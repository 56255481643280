import React, { useState, useEffect } from "react";
import Logo from '../images/logo.png'
import news from '../images/news.webp'
import * as Icon from "react-feather";
import { ToastContainer, toast } from 'react-toastify';
// import NoData from "../noData/noDataFound.jsx";

function SocialNewsFeed(props) {
   
    return (
        <div className="container-fluid outer-padding">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"N"}
                theme="colored" />
           
           <div class="col-md-12">
   {/* <div class="card-columns"> */}
   {/* <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                        <div class="edit_admin_icon">
                            <div class="edit_admin_icon_div">
                            <Icon.Edit />
                            </div>
                            <div class="edit_admin_icon_div admin_text_danger">
                            <Icon.Trash />
                            </div>
                        </div>
                        <div class="col d-flex flex-column position-static">
                            <div class="d-flex justify-content-between align-items-center py-2">
                                <div class="logo-news">
                                    <img src={Logo} width="60" height="60" class="img-fluid" />
                                </div>
                            </div>
                            <div class="news-content">
                                <p>Please read the article about Brampton neighbourhood to receive funding to help combat poverty sourced from</p>
                                <a href="inbrampton.com&nbsp;https://cupe831.com/please-read-the-article-about-brampton-neighbourhood-to-receive-funding-to-help-combat-poverty-sourced-from-inbrampton-com/" rel="noopener" noreferrer="" target="_blank" ><strong>https://cupe831.com/please-read-the-article-about-brampton-neighbourhood-to-receive-funding-to-help-combat-poverty-sourced-from-inbrampton-com/</strong></a>
                            </div>
                        </div>

                    </div>
                    <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                        <div class="edit_admin_icon">
                            <div class="edit_admin_icon_div">
                            <Icon.Edit />
                            </div>
                            <div class="edit_admin_icon_div admin_text_danger">
                            <Icon.Trash />
                            </div>
                        </div>
                        <div class="col d-flex flex-column position-static">
                            <div class="d-flex justify-content-between align-items-center py-2">
                                <div class="logo-news">
                                    <img src={Logo} width="60" height="60" class="img-fluid" />
                                </div>
                            </div>
                            <div class="news-content">
                                <p>In Memory Of John William Dupe</p>
                                <a href="https://www.arbormemorial.ca/brampton/obituaries/john-william-dupe/65749/" rel="noopener" noreferrer="" target="_blank" ><strong>https://www.arbormemorial.ca/brampton/obituaries/john-william-dupe/65749/</strong></a>
                            </div>
                        </div>

                    </div>
                    <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                        <div class="edit_admin_icon">
                            <div class="edit_admin_icon_div">
                            <Icon.Edit />
                            </div>
                            <div class="edit_admin_icon_div admin_text_danger">
                            <Icon.Trash />
                            </div>
                        </div>
                        <div class="col d-flex flex-column position-static">
                            <div class="d-flex justify-content-between align-items-center py-2">
                                <div class="logo-news">
                                    <img src={Logo} width="60" height="60" class="img-fluid" />
                                </div>
                            </div>
                            <div class="news-content">
                              
                                <a href="https://cupe.on.ca/dontbeabully/" rel="noopener" noreferrer="" target="_blank" ><strong>https://cupe.on.ca/dontbeabully/</strong></a>
                            </div>
                        </div>

                    </div>
                    <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                        <div class="edit_admin_icon">
                            <div class="edit_admin_icon_div">
                            <Icon.Edit />
                            </div>
                            <div class="edit_admin_icon_div admin_text_danger">
                            <Icon.Trash />
                            </div>
                        </div>
                        <div class="col d-flex flex-column position-static">
                            <div class="d-flex justify-content-between align-items-center py-2">
                                <div class="logo-news">
                                    <img src={Logo} width="60" height="60" class="img-fluid" />
                                </div>
                            </div>
                           <div>
                            <img src={news} alt="news" className="img-fluid news-list-img"/>
                           </div>
                        </div>

                    </div>
                    <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                        <div class="edit_admin_icon">
                            <div class="edit_admin_icon_div">
                            <Icon.Edit />
                            </div>
                            <div class="edit_admin_icon_div admin_text_danger">
                            <Icon.Trash />
                            </div>
                        </div>
                        <div class="col d-flex flex-column position-static">
                            <div class="d-flex justify-content-between align-items-center py-2">
                                <div class="logo-news">
                                    <img src={Logo} width="60" height="60" class="img-fluid" />
                                </div>
                            </div>
                           <div>
                            <img src={news} alt="news" className="img-fluid news-list-img"/>
                           </div>
                        </div>
                        <div class="news-content">
                              <p>Click the link below!</p>
                              <a href="https://rukovoditel.cupe.ca/index.php?module=ext/public/form&id=87" rel="noopener" noreferrer="" target="_blank" ><strong>https://rukovoditel.cupe.ca/index.php?module=ext/public/form&id=87</strong></a>
                          </div>   
                    </div> */}
                    
{/* </div> */}
<div class="alert alert-danger bg-white text-center" role="alert">
                News Available
            </div>
</div>
        </div>
    );
}

export default SocialNewsFeed;
