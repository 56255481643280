import React, { useEffect, useRef ,useState, Suspense } from "react";
import Banner from "./banner";
import Statement from "./statement";
import BottomSection from "./bottomSection";
let Header = React.lazy(() => import("../layouts/header"))
let Footer = React.lazy(() => import("../layouts/footer"))
const EqualityStatement = () => {

   
    return (
        <div className="footer_white eq-wrappers">
             <Suspense fallback={<div></div>}>
            <div className="container-fluid equalityStatment-wrapper header-position">
                <Header />
                <Banner/>
                <Statement/>
                 <BottomSection/>
            </div>
            <Footer />
            </Suspense>
        </div>
    );
};

export default EqualityStatement;
