import React, { Suspense } from "react";
import Sidebar from "./Layout/sidebar";
const Header = React.lazy(() => import("./Layout/header-admin"));

const ResetPassword = () => {
    return (
        <div>
            <div className="container-fluid">
                <Suspense fallback={<div></div>}>
                    <Header />
                </Suspense>
                <div className="row mx-0">
                    <div className="Sidebar-left">
                        <Sidebar />
                    </div>
                    <div className="Sidebar-right">
                        <div className="container login_theme">
                            <form className="col-md-6 m-auto form-signin">
                                <div className="text-center mb-4">
                                    <h1 className="h3 mb-3 font-weight-normal">Reset Password</h1>
                                </div>
                                <div className="form-label-group">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        className="form-control"
                                        placeholder="New Password"
                                        required
                                    />
                                    <label className="form-label-text" htmlFor="newPassword">
                                        New Password
                                    </label>
                                </div>
                                <div className="form-label-group">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <label className="form-label-text" htmlFor="confirmPassword">
                                        Confirm Password
                                    </label>
                                </div>


                                <div className="alert alert-success alert alert-danger">
                                    Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.
                                </div>
                                <button
                                    className="btn btn-lg btn-dark btn-block"
                                    type="submit"
                                >
                                    CONFIRM
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense />
        </div>
    );
};
export default ResetPassword;
