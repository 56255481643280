import React, { Suspense, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Sidebar from "../Layout/sidebar"
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import Filter from "../images/Filter-icon.png"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import { ToastContainer, toast } from 'react-toastify';
let Header = React.lazy(() => import("../Layout/header-admin"))


function MemberManagement() {


    useEffect(() => {
        setTimeout(() => {
            var element = document.getElementById("members-menu");
            element.classList.add("active");
        }, 1000);
    }, [])

    return (
        <div className="admin-wrapper container-fluid" >
            <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
            <Suspense fallback={<div></div>}>
                <Header></Header>
                <div className="container-fluid">
                    <div className="banner-top-wrapper">
                        <img src={BannerImg} className="banner-top-img img-fluid"></img>
                        <img src={cupeLogo} className="banner-left-img"></img>
                        <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                    </div>
                    <div className="row mx-0 mt-5">
                        <div className="Sidebar-left">
                            <Sidebar />
                        </div>
                        <div className="Sidebar-right">
                            <div className="card border-0">
                                <div className="card-body py-0">
                                    <div className="col-md-12 mb-3 px-0">
                                        <div className="row m-0">
                                            <div className="col-md-6">
                                                <h1 className="mainHead mb-0 mt-2">Member </h1>
                                            </div>
                                            <div className="col-md-12 col-lg-6 text-right">
                                                <span className="btn btn-sm btn-dark mr-2 col-12 col-md-4 col-lg-6 col-xl-4 my-2 my-md-0" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>Export Member List </span>
                                                <Link className="btn btn-sm btn-danger col-12 col-md-3 col-lg-5 col-xl-4" to="" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>Create Member</Link>
                                            </div>

                                        </div>
                                        <hr></hr>

                                        <div className="row m-0 alignItemCenter justify-content-between">
                                            <div className="col-md-12 col-lg-4 filter-wrapper mb-3 mb-lg-0">
                                                <input className="form-control search-text" id="myInput" type="text" placeholder="Search for a Member.." />

                                            </div>

                                                    <div className="position-relative row col-md-12 col-lg-8 col-xl-6 ml-1 ml-md-0">
                                                        <span className="col-md-5 col-lg-4 col-12 mt-2">
                                                            Total Count :
                                                        </span>
                                                        <span className="btn btn-dark col-12 col-md-3 col-lg-3 col-xl-3 my-2 my-md-0" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                                                            {/* <Icon.Menu className="mr-2" type="button" data-toggle="" size={14} /> */}
                                                            <img src={Filter} alt="filter" width="18" height="18" className="mr-1 img-fluid" />
                                                            Filter
                                                        </span>
                                                        <span className="btn btn-outline-dark ml-0 ml-md-4 ml-lg-1 ml-xl-3 col-md-3 col-12 col-lg-4 col-xl-4" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                                                            Clear Filter
                                                        </span>

                                                    </div>

                                        </div>
                                    </div>
                                    <div className="table-responsive custom-table-responsive px-0">
                                        <table className="table custom-table table-striped">
                                            <thead>
                                                <tr className="custom-th">
                                                    <th scope="col" style={{ width: "40px" }}>
                                                        <div className="form-check mt-1">
                                                            <input type="checkbox" className="form-check-input" id="selectAll" />
                                                            <label className="form-check-label" for="selectAll">&nbsp;</label>
                                                        </div>
                                                    </th>
                                                    <th scope="col">Member</th>
                                                    <th scope="col">Unit</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr scope="row">
                                                    <td scope="row">
                                                        <div className="form-check mt-1">
                                                            <input type="checkbox" className="form-check-input" id="selectcheck" />
                                                            <label className="form-check-label" for="selectcheck">&nbsp;</label>
                                                        </div>
                                                    </td>

                                                    <td className="d-flex align-items-center">
                                                        <div className="mr-2">
                                                            {/* <img src={LogoImg} height={20} alt={memberListData.logoAlt} className="table-img-logo" /> */}
                                                        </div>

                                                        <div className="m-0">
                                                            <div>
                                                            Brenda Lee 
                                                            </div>
                                                            <div><small className="d-block">Brenda.lee@gmail.com</small></div>
                                                        </div>
                                                    </td>
                                                    <td>Temporary</td>
                                                    <td><a href="#"></a></td>
                                                    <td>905 222 2222
</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between align-items-center position-relative">
                                                            <span className="news-icon-top">

                                                                <div className="dropdown dropdown-member">

                                                                    <Icon.MoreVertical className="ml-2 text-black" type="button" data-toggle="dropdown" aria-expanded="false" size={14} />

                                                                    <div className="dropdown-menu">
                                                                        <Link className="dropdown-item" to="/site-admin/profile">
                                                                            Profile
                                                                        </Link>
                                                                        <span className="dropdown-item">
                                                                            Delete
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr className="spacer"><td colSpan="6"></td></tr>
                                                <tr scope="row">
                                                    <td scope="row">
                                                        <div className="form-check mt-1">
                                                            <input type="checkbox" className="form-check-input" id="selectcheck" />
                                                            <label className="form-check-label" for="selectcheck">&nbsp;</label>
                                                        </div>
                                                    </td>

                                                    <td className="d-flex align-items-center">
                                                        <div className="mr-2">
                                                            {/* <img src={LogoImg} height={20} alt={memberListData.logoAlt} className="table-img-logo" /> */}
                                                        </div>

                                                        <div className="m-0">
                                                            <div>
                                                            Dan Smith 
                                                            </div>
                                                            <div><small className="d-block">dan.smith@gmail.com</small></div>
                                                        </div>
                                                    </td>
                                                    <td>NA</td>
                                                    <td><a href="#"></a></td>
                                                    <td>905 333 3333</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between align-items-center position-relative">
                                                            <span className="news-icon-top">

                                                                <div className="dropdown dropdown-member">

                                                                    <Icon.MoreVertical className="ml-2 text-black" type="button" data-toggle="dropdown" aria-expanded="false" size={14} />

                                                                    <div className="dropdown-menu">
                                                                        <Link className="dropdown-item" to="/site-admin/profile">
                                                                            Profile
                                                                        </Link>
                                                                        <span className="dropdown-item" >
                                                                            Delete
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default MemberManagement;
