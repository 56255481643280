import React, { Suspense, useState, useEffect } from 'react'
import Sidebar from "../Layout/sidebar";
import BannerImg from "../images/home-banner.png";
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg";
import cupeLogo from "../images/sub-logo-cupe.jpg";
import ImgBanner from '../images/user_social.avif'
import news2 from '../images/canada-news2.webp'
import news3 from '../images/visa.jpg'
import "../css/social_default.css";
import "../css/social_streams.css";
import {
  FaFacebookF,
  FaInstagramSquare,
  FaTumblr,
  FaYoutube,
  FaSearch,
  FaTwitter,
} from "react-icons/fa";
let Header = React.lazy(() => import("../Layout/header-admin"))
function SocialMeList() {
  useEffect(() => {
    setTimeout(() => {
      var element = document.getElementById("social-media-menu");
      element.classList.add("active");
    }, 1000);
  }, []);
  return (
    <div>

      <div className="container-fluid padding-wrapper">
        <Suspense fallback={<div></div>}>
          <Header></Header>
          <div className="container-fluid">
            <div className="banner-top-wrapper">
              <img src={BannerImg} className="banner-top-img img-fluid"></img>
              <img src={cupeLogo} className="banner-left-img"></img>
              <img src={BannerImgBtm} className="banner-top-btmimg"></img>
            </div>
            <div className="row mx-0">
              <div className="Sidebar-left">
                <Sidebar />
              </div>
              <div className="Sidebar-right">
                <div className="social-wrapper col-md-12">
                  <ul className="nav social-nav">
                    <li className="nav-item social-nav-li">
                      <span
                        className="currentDiv"
                        id="divAll"
                      >
                        All
                      </span>
                    </li>

                    <li className={"nav-item social-nav-li"}>
                      <span
                        className=""
                        id="divTumblr"
                      >
                        <FaTumblr size={16} />
                      </span>
                    </li>



                    <li className={"nav-item social-nav-li"}>
                      <span
                        className=""
                        id="divYoutube"
                      >
                        <FaYoutube size={16} />
                      </span>
                    </li>

                    <li className={"nav-item social-nav-li"}>
                      <span
                        className=""
                        id="divTwitter"
                      >
                        <FaTwitter size={16} />
                      </span>
                    </li>
                    <li className={"nav-item social-nav-li"}>
                      <span
                        className=""
                        id="divFacebook"
                      >
                        <FaFacebookF size={16} />
                      </span>
                    </li>
                    <li className={"nav-item social-nav-li"}>
                      <span
                        className=""
                        id="divInstagram"
                      >
                        <FaInstagramSquare size={16} />
                      </span>
                    </li>

                    <li className="nav-item search_social">
                      <input
                        type="text"
                        placeholder="search"
                        id="socialSearch"

                      />
                      <FaSearch />
                    </li>
                    <li>
                      <button
                        className="btn-save"
                        id="clearSearchBtn"
                        style={{ display: "none" }}
                      >
                        Clear
                      </button>
                    </li>
                  </ul>
                  <div className="card-columns mt-3 mt-lg-5">
                    <div className="card row  border flex-md-row mb-4 mx-0 shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          <div className="logo-news">
                            <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500" />
                          </div>
                        </div>
                        <div className="news-content">
                          <p>Australia's intelligence chief has said he had no reason to dispute Canadian Prime Minister Justin Trudeau's claims on India linked to the death of terrorist Hardeep Singh Nijjar.
                          </p>
                          <a href="https://www.indiatoday.in/world/story/mike-burgess-says-no-reason-to-dispute-canada-claims-on-terrorist-hardeep-singh-nijjar-killing-india-canada-row-2450864-2023-10-19">
                            'No reason to dispute' Canada's claim against India: Australian intel chief
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          {/* <div className="logo-news">
                <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500"/>
              </div> */}
                        </div>
                        <div className="news-content">
                          <p>‘Reject any attempt to...’: India slams Canada response on diplomats’ departure

                          </p>
                          <a href="https://www.indiatoday.in/india/story/reject-any-attempt-to-india-slams-canada-ministers-response-on-diplomats-departure-2451576-2023-10-20?utm_source=directhp&utm_medium=clicktopstories&utm_campaign=hptopstories">
                            India on Friday slammed Canada's remarks after Ottawa pulled out 41 diplomats, saying "New Delhi rejects any attempt to portray the implementation of parity as a violation of international norms".
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          {/* <div className="logo-news">
                <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500"/>
              </div> */}
                        </div>
                        <div className="news-content">
                          <p>After UAE President, Trudeau raises India-Canada diplomatic row with Jordan King

                          </p>
                          <a href="https://www.indiatoday.in/world/story/after-uae-president-trudeau-raises-india-canada-diplomatic-row-with-jordan-king-2446978-2023-10-10">
                            Prime Minister Justin Trudeau spoke with King Abdullah II bin Al-Hussein of Jordan on Sunday, and provided him with an "update on the situation between Canada and India", as he noted the significance of "adhering to the rule of law and respecting the Vienna Convention on Diplomatic Relations".
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        {/* <div class="d-flex justify-content-between align-items-center py-2">
                          <div className="logo-news">
                            <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500" />
                          </div>
                        </div> */}
                        <div className="news-content">
                          <p>Indian Infosys techie turns factory worker, trucker drives to success in Canada

                          </p>
                          <a href="https://www.indiatoday.in/world/story/canada-india-relations-infosys-techie-factory-worker-punjab-truck-driver-success-canadian-jobs-indians-immigration-2449831-2023-10-17">
                            In the last of our three-part series, we talk about an Infosys software engineer who shifted to Canada and is working in a production line. We also see the rise of a youngster as a truck driver. Experts also reveal how there’s a mismatch in aspirations, education and job opportunities in Canada.
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          <div className="logo-news">
                            <img src={news2} className="img-fluid" alt='logo-img' width="500" height="500" />
                          </div>
                        </div>
                        <div className="news-content">
                          <p>India visa processing will slow, according to Canada’s Immigration Minister

                          </p>
                          <a href="https://www.cicnews.com/2023/10/breaking-india-visa-processing-will-slow-according-to-canadas-immigration-minister-1040495.html#gs.76emyb">
                            The ongoing diplomatic dispute between Canada and India will result in slower visa processing of Indians looking to come to Canada.

                            This was just confirmed by Canada's Immigration Minister Marc Miller in a press conference in Ottawa on Thursday October 19.

                            The reason for the slowdown is India's request that Canada reduce the number of diplomats it has stationed in India.
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          {/* <div className="logo-news">
                <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500"/>
              </div> */}
                        </div>
                        <div className="news-content">
                          <p>Australia's intelligence chief has said he had no reason to dispute Canadian Prime Minister Justin Trudeau's claims on India linked to the death of terrorist Hardeep Singh Nijjar.

                          </p>
                          <a href="https://www.indiatoday.in/world/story/mike-burgess-says-no-reason-to-dispute-canada-claims-on-terrorist-hardeep-singh-nijjar-killing-india-canada-row-2450864-2023-10-19">
                            'No reason to dispute' Canada's claim against India: Australian intel chief
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          {/* <div className="logo-news">
                <img src={ImgBanner} className="img-fluid" alt='logo-img' width="500" height="500"/>
              </div> */}
                        </div>
                        <div className="news-content">
                          <p>Police prepare for Queen's homecoming as enforcement concerns arise

                          </p>
                          <a href="https://www.cbc.ca/news/canada/ottawa/queen-s-university-partying-kingston-police-homecoming-enforcement-1.7001311">
                            The student government at Queen's University is speaking out against steep fines and the risk of a court summons facing partiers due to enhanced police enforcement around campus.

                            Representatives of the Alma Mater Society appeared before the Kingston Police Services Board on Thursday to raise concerns about the approach they described as "unwarranted."
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card row  border flex-md-row mb-4 mx-0  shadow-sm h-md-250 position-relative">
                      <div className="col d-flex flex-column position-static">
                        <div class="d-flex justify-content-between align-items-center py-2">
                          <div className="logo-news">
                            <img src={news3} className="img-fluid" alt='logo-img' width="500" height="500" />
                          </div>
                        </div>
                        <div className="news-content">
                          <p>Canada withdraws 41 diplomats from India

                          </p>
                          <a href="https://www.bbc.com/news/world-us-canada-67162218">
                            Forty-one Canadian diplomats have recently left India amid a rift over the murder of a Sikh separatist leader on Canadian soil.

                            India asked Canada two weeks ago to withdraw dozens of its diplomatic staff and threatened to remove their immunity if they remained.

                            Canadian officials called the threat a "violation of international law".
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </div>

    </div>
  )
}
export default SocialMeList;