import React, {Suspense, useState, useEffect } from "react";
import ProfileInfo from "./profile-info";
import PersonalInfo from "./profile-personalInfo";
import UnionInfo from "./profile-union-info";
import ProfileEmployment from "./profile-employment";
import DrivingLicence from "./profile-driving-licence";
import JobLocations from "./profile-joblocation";
import FamilyMembers from "./profile-familymembers";
import Notes from "./profile-notes";
import Notification from "./profile-notification";
import Sidebar from "./Layout/sidebar"
import * as Icon from "react-feather";
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
let Header = React.lazy(() => import("./Layout/header-admin"))
function Profile() {

  
  useEffect(() => {    
    setTimeout(() => {
     
      function handleTabClick(tabId, contentId) {
        ['home-vertical-tab', 'profile-vertical-tab', 'diverse-sectors-tab', 'notification-tab'].forEach(function(id) {
            document.getElementById(id).classList.toggle('active', id === tabId);
        });
    
        ['info-vertical', 'profile-vertical', 'diverse-sectors', 'notification'].forEach(function(id) {
            var element = document.getElementById(id);
            element.style.display = (id === contentId) ? 'block' : 'none';
            element.classList.toggle("fade", id !== contentId);
            element.classList.toggle("show", id === contentId);
        });
    }
    
    document.getElementById("home-vertical-tab").onclick = function() {
        handleTabClick('home-vertical-tab', 'info-vertical');
    };
    
    document.getElementById("profile-vertical-tab").onclick = function() {
        handleTabClick('profile-vertical-tab', 'profile-vertical');
    };
    
    document.getElementById("diverse-sectors-tab").onclick = function() {
        handleTabClick('diverse-sectors-tab', 'diverse-sectors');
    };
    
    document.getElementById("notification-tab").onclick = function() {
        handleTabClick('notification-tab', 'notification');
    };
    
    document.getElementById("diverse-sectors-tab1").onclick = function() {
        handleTabClick('diverse-sectors-tab1', 'diverse-sectors');
    };
    
    document.getElementById("notification-tab1").onclick = function() {
        handleTabClick('notification-tab1', 'notification');
    };
    
    var element = document.getElementById("services-menu");
    element.classList.add("active");
  }, 1000);  
  }, []);
 
  
  return (
    <div className="container-fluid padding-wrapper">
       
      <Suspense fallback={<div></div>}>
      <Header></Header>
      <div className="container-fluid">
      <div className="banner-top-wrapper">
                        <img src={BannerImg} className="banner-top-img img-fluid"></img>
                        <img src={cupeLogo} className="banner-left-img"></img>
                        <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                    </div>
        <div className="row mx-0">
          <div className="Sidebar-left">
            <Sidebar />
          </div>
          <div className="Sidebar-right">

            <div className="col-md-12 mb-5 px-0">

              <div className="tab-vertical">
                <ul className="nav nav-tabs" id="myTab3" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="home-vertical-tab" >Info</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="profile-vertical-tab">Personal Info</a>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <a className="nav-link" id="diverse-sectors-tab">Union Info</a>
                  </li>
                  {/* <li className="nav-item d-none d-md-block">
                    <a className="nav-link" id="equality-statement-tab" data-toggle="tab" href="#equality-statement" role="tab" aria-controls="equality-statement" aria-selected="false">Employement</a>
                  </li>
                  <li className="nav-item d-none d-lg-block">
                    <a className="nav-link" id="contact-edit-tab" data-toggle="tab" href="#contact-edit" role="tab" aria-controls="contact-edit" aria-selected="false">Driver License</a>
                  </li>
                  <li className="nav-item d-none d-lg-block">
                    <a className="nav-link" id="job-locations-tab" data-toggle="tab" href="#job-locations" role="tab" aria-controls="job-locations" aria-selected="false">Job Locations</a>
                  </li>
                    <li className="nav-item d-none d-xl-block">
                      <a className="nav-link" id="family-members-tab" data-toggle="tab" href="#family-members" role="tab" aria-controls="family-members" aria-selected="false">Family Members</a>
                    </li>
                    <li className="nav-item d-none d-xl-block">
                      <a className="nav-link" id="notes-tab" data-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">Notes</a>
                    </li> */}
                    <li className="nav-item d-none d-xl-block">
                      <a className="nav-link" id="notification-tab">Notification</a>
                    </li>
                    <div class="btn-group dropleft d-block d-xl-none">
                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      <Icon.MoreVertical className="text-black" size={14} />
                      </button>
                        <div className="dropdown-menu"  role="tablist">
                          <div className="dropdown-item d-block d-md-none">
                            <a className="nav-link" id="diverse-sectors-tab1">Union Info</a>
                          </div>                       
                          <div className="dropdown-item">
                          <a className="nav-link" id="notification-tab1" >Notification</a>
                          </div>
                        </div>
                          {/*  <div className="dropdown-item d-block d-md-none">
                            <a className="nav-link" id="equality-statement-tab" data-toggle="tab" href="#equality-statement" role="tab" aria-controls="equality-statement" aria-selected="false">Employement</a>
                          </div>
                          <div className="dropdown-item d-block d-md-none">
                            <a className="nav-link" id="contact-edit-tab" data-toggle="tab" href="#contact-edit" role="tab" aria-controls="contact-edit" aria-selected="false">Driver License</a>
                          </div>
                          <div className="dropdown-item d-block d-lg-none">
                            <a className="nav-link" id="job-locations-tab" data-toggle="tab" href="#job-locations" role="tab" aria-controls="job-locations" aria-selected="false">Job Locations</a>
                          </div>
                          <div className="dropdown-item">
                          <a className="nav-link" id="family-members-tab" data-toggle="tab" href="#family-members" role="tab" aria-controls="family-members" aria-selected="false">Family Members</a>
                          </div>
                          <div className="dropdown-item">
                          <a className="nav-link" id="notes-tab" data-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">Notes</a> 
                          </div>*/}
                    </div>

                </ul>

                <div className="card border-0">
                  <div className="card-body p-0">
                    <div className="tab-content custom-card" id="myTabContent3">
                      <div className="tab-pane fade show active" id="info-vertical" role="tabpanel" aria-labelledby="home-vertical-tab"  >
                        <ProfileInfo />
                      </div>
                      <div className="tab-pane fade" id="profile-vertical" role="tabpanel" aria-labelledby="profile-vertical-tab" >
                        <PersonalInfo />
                      </div>
                      <div className="tab-pane fade" id="diverse-sectors" role="tabpanel" aria-labelledby="diverse-sectors-tab"  >
                        <UnionInfo/>
                      </div>
                      {/* <div className="tab-pane fade" id="equality-statement" role="tabpanel" aria-labelledby="equality-statement-tab"  >
                         <ProfileEmployment /> 
                      </div>
                      <div className="tab-pane fade" id="contact-edit" role="tabpanel" aria-labelledby="contact-edit-tab"  >
                        <DrivingLicence />
                      </div>
                      <div className="tab-pane fade" id="job-locations" role="tabpanel" aria-labelledby="job-locations"  >
                        <JobLocations/>
                      </div>
                      <div className="tab-pane fade" id="family-members" role="tabpanel" aria-labelledby="family-members"  >
                        <FamilyMembers />
                      </div>
                      <div className="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes"  >
                        <Notes/>
                      </div> */}
                      <div className="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notes"  >
                        <Notification />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </Suspense>
    </div>
  );
}

export default Profile;
