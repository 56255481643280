import axios from "axios";
export async function saveData(parameter) {
    console.log("parameter", parameter);
    try {
        // let token = JSON.stringify(parameter)
        const response = await axios({ method: "POST", url: process.env.REACT_APP_API_URI, data: parameter, headers: { Accept: "application/json" } })
        console.log("response", response);
        return response.data
    }
    catch (error) {
        throw error
    }
}