import React, {Suspense,seState, useEffect } from "react";
// import { useDispatch, useSelector } from 'react-redux';
// import Header from "../Layout/header-admin";
// import ExecutiveList from "../admin/executive-list";
import BannerEdit from "./banner-edit";
import AboutEdit from "./about-edit";
import DiversSectorsEdit from "./divers-sectors";
import EqualityStatementEdit from "./equality-statement";
import ContactEdit from "./contact-edit";
import SocialNewsFeed from "./social-news-feed";
// import CustomizeHomeTab from "./customize-home-tab";
import Executives from "./executive-list";
import Sidebar from "../Layout/sidebar"
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import * as Icon from "react-feather";

let Header = React.lazy(() => import("../Layout/header-admin"))
function Executive() {



  useEffect(() => {
    setTimeout(() => {

      function handleTabClick(tabId, contentId) {
        ['home-vertical-tab', 'profile-vertical-tab', 'diverse-sectors-tab', 'contact-edit-tab', 'news-edit-tab', 'executive-tab', 'equality-statement-tab'].forEach(function (id) {
          var element = document.getElementById(id);
          if (element !== null) {
            document.getElementById(id).classList.toggle('active', id === tabId);
          }
        });
        ['home-vertical', 'profile-vertical', 'diverse-sectors', 'equality-statement', 'contact-edit', 'news-edit', 'executive'].forEach(function (id) {
          var element = document.getElementById(id);
          element.style.display = (id === contentId) ? 'block' : 'none';
          element.classList.toggle("fade", id !== contentId);
          element.classList.toggle("show", id === contentId);
        });
      }

      document.getElementById("home-vertical-tab").onclick = function () {
        handleTabClick('home-vertical-tab', 'home-vertical');
      };

      document.getElementById("profile-vertical-tab").onclick = function () {
        handleTabClick('profile-vertical-tab', 'profile-vertical');
      };

      document.getElementById("diverse-sectors-tab").onclick = function() {
        handleTabClick('diverse-sectors-tab', 'diverse-sectors');
      };
  
      document.getElementById("equality-statement-tab").onclick = function() {
        handleTabClick('equality-statement-tab', 'equality-statement');
      };
      document.getElementById("contact-edit-tab").onclick = function() {
        handleTabClick('contact-edit-tab', 'contact-edit');
      };
      document.getElementById("news-edit-tab").onclick = function() {
        handleTabClick('news-edit-tab', 'news-edit');
      };
      document.getElementById("executive-tab").onclick = function() {
        handleTabClick('executive-tab', 'executive');
      };
      document.getElementById("diverse-sectors-tab1").onclick = function() {
        handleTabClick('diverse-sectors-tab1', 'diverse-sectors');
      };
  
      document.getElementById("equality-statement-tab1").onclick = function() {
        handleTabClick('equality-statement-tab1', 'equality-statement');
      };
      document.getElementById("contact-edit-tab1").onclick = function() {
        handleTabClick('contact-edit-tab1', 'contact-edit');
      };
      document.getElementById("news-edit-tab1").onclick = function() {
        handleTabClick('news-edit-tab1', 'news-edit');
      };
      document.getElementById("executive-tab1").onclick = function() {
        handleTabClick('executive-tab1', 'executive');
      };
      var element = document.getElementById("union-menu");
      element.classList.add("active");
    }, 1000);
  }, [])


  return (
    <div>
      <Suspense fallback={<div></div>}>
        <div className="container-fluid padding-wrapper">
          <Header></Header>
          <div className="container-fluid">
            <div className="banner-top-wrapper">
              <img src={BannerImg} className="banner-top-img img-fluid"></img>
              <img src={cupeLogo} className="banner-left-img"></img>
              <img src={BannerImgBtm} className="banner-top-btmimg"></img>
            </div>
            <div className="row mx-0 mt-3">
              <div className="Sidebar-left">
                <Sidebar />
              </div>
              <div className="Sidebar-right">
                <div className='col-md-4 my-3 px-0'>
                  {/* <Select
                  // className="custom-select w-100"
                  placeholder="Select website"
                  options={newArray.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  onChange={selectSite}
                /> */}
                </div>

                <div className="col-md-12 px-0 mb-5">

                  <div className="row">
                    <div className="btn-wrapper mb-2">
                      {/* <button className="btn btn-danger" href="#" data-toggle="modal" data-target="#preview">Preview</button>
                  <button className="btn btn-danger" data-toggle="modal" data-target="#change-theme">Change Theme</button> */}
                    </div>
                  </div>
                  <div className="tab-vertical">
                    <ul className="nav nav-tabs" id="myTab3" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link" id="home-vertical-tab">Banner</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="profile-vertical-tab">About</a>
                      </li>
                      <li className="nav-item d-none d-md-block">
                        <a className="nav-link" id="diverse-sectors-tab">Diverse Sectors</a>
                      </li>
                      <li className="nav-item d-none d-md-block">
                        <a className="nav-link" id="equality-statement-tab">Equality Statement</a>
                      </li>
                      <li className="nav-item d-none d-lg-block">
                        <a className="nav-link" id="contact-edit-tab">Contact Us</a>
                      </li>
                      <li className="nav-item d-none d-lg-block">
                        <a className="nav-link" id="news-edit-tab">News</a>
                      </li>
                      <li className="nav-item d-none d-lg-block">
                        <a className="nav-link" id="executive-tab">Executive</a>
                      </li>
                    <div class="btn-group dropleft d-block d-xl-none">
                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      <Icon.MoreVertical className="text-black" size={14} />
                      </button>
                        <div className="dropdown-menu">
                          <div className="dropdown-item d-block d-md-none">
                            <a className="nav-link" id="diverse-sectors-tab1">Diverse Sectors</a>
                          </div>
                          <div className="dropdown-item d-block d-md-none">
                            <a className="nav-link" id="equality-statement-tab1" >Equality Statement</a>
                          </div>
                          <div className="dropdown-item d-block d-lg-none">
                            <a className="nav-link" id="contact-edit-tab1" >Contact Us</a>
                          </div>
                          <div className="dropdown-item d-block d-lg-none">
                            <a className="nav-link" id="news-edit-tab1" >News</a>
                          </div>
                          <div className="dropdown-item d-block d-lg-none">
                            <a className="nav-link" id="executive-tab1" >Executive</a>
                          </div>
                        </div>
                    </div>
                    </ul>
                    <div className="card border-0">
                      <div className="card-body p-0">
                        <div className="tab-content custom-card pt-md-4 pt-0" id="myTabContent3">
                          <div className="tab-pane fade show active" id="home-vertical" role="tabpanel" aria-labelledby="home-vertical-tab">
                            <BannerEdit />
                          </div>
                         <div className="tab-pane fade" id="profile-vertical" role="tabpanel" aria-labelledby="profile-vertical-tab">
                          <AboutEdit />
                        </div>
                          <div className="tab-pane fade" id="diverse-sectors" role="tabpanel" aria-labelledby="diverse-sectors-tab">
                          <DiversSectorsEdit />
                        </div>
                        <div className="tab-pane fade" id="equality-statement" role="tabpanel" aria-labelledby="equality-statement-tab">
                          <EqualityStatementEdit />
                        </div>
                       <div className="tab-pane fade" id="contact-edit" role="tabpanel" aria-labelledby="contact-edit-tab">
                          <ContactEdit />
                        </div>
                         <div className="tab-pane fade" id="news-edit" role="tabpanel" aria-labelledby="news-edit-tab">
                          <SocialNewsFeed />
                        </div>
                        <div className="tab-pane fade" id="executive" role="tabpanel" aria-labelledby="executive-edit-tab">
                          <Executives/>
                        </div> 
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default Executive;
