import React, { Suspense, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Sidebar from "../Layout/sidebar"
import BannerImg from "../images/home-banner.png"
import Img1 from "../images/promotion1.webp"
import Img2 from "../images/promotion2.webp"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
let Header = React.lazy(() => import("../Layout/header-admin"))
function Ads() {
    useEffect(() => {
        setTimeout(() => {
            var element = document.getElementById("ads-menu");
            element.classList.add("active");
        }, 1000);
    }, []);
    return (
        <div>

            <Suspense fallback={<div></div>}>
                <div className="admin-wrapper container-fluid">
                <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
                    <Header></Header>
                    <div className="container-fluid">
                        <div className="banner-top-wrapper">
                            <img src={BannerImg} className="banner-top-img img-fluid"></img>
                            <img src={cupeLogo} className="banner-left-img"></img>
                            <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                        </div>
                        <div className="row mx-0 mt-5">
                            <div className="Sidebar-left">
                                <Sidebar />
                            </div>
                            <div className="Sidebar-right">
                                <div className="card border-0">
                                    <div className="card-body py-0">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h1 className="mainHead mb-0 mt-2">Ads</h1>
                                            </div>

                                            <div className="col-md-6 text-right">
                                                <Link className="btn btn-sm btn-danger mt-2" to="" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>New Ads </Link>
                                            </div>

                                        </div>


                                        <hr></hr>
                                        <div className="card border-0">

                                            <div className="card-body py-0">
                                                <div className="row">
                                                    <div className="col-lg-6 d-flex pl-0">
                                                        <div className="filter-wrapper w-100 mb-0 d-flex align-items-center mb-2">
                                                            <input className="form-control search-text" id="myInput" type="text" placeholder="Search Title.." />
                                                            <button type="button" className="btn btn-dark ads_search"><Icon.Search /></button>
                                                        </div>
                                                    </div>

                                                    <div className="table-responsive custom-table-responsive mt-4 px-0">
                                                        <table className="table custom-table table-striped">
                                                            <thead>
                                                                <tr className="custom-th">
                                                                    <th scope="col">Title </th>
                                                                    <th scope="col">Image </th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Public</th>
                                                                    <th scope="col">Edit  </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr scope="row">
                                                                    <td>General Meeting at HQ - 8PM - Oct 18</td>
                                                                    <td>  <a href="">
                                                                        <img src={Img1} height={20} alt="" className="table-img-logo table_img_edit" />
                                                                    </a></td>
                                                                    <td>
                                                                        <span className="text-success">Active</span>
                                                                        <div className="custom-control custom-switch mb-0 mr-2">
                                                                            <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                                                            />
                                                                            <label className="custom-control-label" for="customSwitch1"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="custom-control custom-switch mb-0 mr-2">
                                                                            <input type="checkbox" className="custom-control-input" id="customSwitch12"
                                                                            />
                                                                            <label className="custom-control-label" for="customSwitch12"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">

                                                                            <Link to="">
                                                                                <Icon.Edit style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                                                                            </Link>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                                <tr scope="row">
                                                                    <td>Welcome to Demo</td>
                                                                    <td>  <a href="">
                                                                        <img src={Img2} height={20} alt="" className="table-img-logo table_img_edit" />
                                                                    </a></td>
                                                                    <td>
                                                                        <span className="text-danger">Inactive</span>
                                                                        <div className="custom-control custom-switch mb-0 mr-2">
                                                                            <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                                                            />
                                                                            <label className="custom-control-label" for="customSwitch1"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="custom-control custom-switch mb-0 mr-2">
                                                                            <input type="checkbox" className="custom-control-input" id="customSwitch12"
                                                                            />
                                                                            <label className="custom-control-label" for="customSwitch12"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">

                                                                            <Link to="">
                                                                                <Icon.Edit style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                                                                            </Link>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Suspense >
        </div >
    );
}
export default Ads;
