import React, { Suspense, useState, useEffect } from "react";
import Sidebar from "../Layout/sidebar"
import { Link } from "react-router-dom";
import BannerImg from "../images/home-banner.png"
import Promotion1 from "../images/promotion1.webp";
import Promotion2 from "../images/promotion2.webp";
import Promotion3 from "../images/promotion3.webp";
import Promotion4 from "../images/promotion4.jpg";
import Promotion5 from "../images/promotion5.webp";
import Promotion6 from "../images/promotion6.webp";
import Promotion7 from "../images/promotion7.webp";
import Promotion8 from "../images/promotion8.jpg";
import Promotion9 from "../images/promotion9.webp";
import Promotion10 from "../images/promotion10.jpg";
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import * as Icon from "react-feather";
let Header = React.lazy(() => import("../Layout/header-admin"))


function PromotionSiteAdmin() {
    useEffect(() => {
        setTimeout(() => {
            var element = document.getElementById("promotion-menu");
            element.classList.add("active");
        }, 1000);
    }, []);
    return (
        <div className="container-fluid padding-wrapper">
            <Suspense fallback={<div></div>}>
                <Header></Header>
                <div className="container-fluid">
                    <div className="banner-top-wrapper">
                        <img src={BannerImg} className="banner-top-img img-fluid"></img>
                        <img src={cupeLogo} className="banner-left-img"></img>
                        <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                    </div>
                    <div className="row mx-0">
                        <div className="Sidebar-left">
                            <Sidebar />
                        </div>
                        <div className="Sidebar-right">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className="mainHead mb-0 mt-2">Promotions</h1>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Link className="btn btn-sm btn-danger mt-2" to="">Add Promotions </Link>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="">
                                    <div className="col-md-12">
                                        <div className="card-columns">
                                            <div class="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div class="col py-3 d-flex flex-column position-static">
                                                    <div class="">
                                                        <a href="" target="_blank">
                                                            <img src={Promotion1} alt="UserImg" class="img-fluid news-list-img" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion2}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion3}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion4}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion5}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion6}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion7}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion8}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion9}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card row border flex-md-row mb-2 shadow-sm h-md-250 position-relative">
                                                <div className='edit_admin_icon'>
                                                    <div className='edit_admin_icon_div'>
                                                        <Link to="">
                                                            <Icon.Edit stroke="white" />
                                                        </Link>
                                                    </div>
                                                    <div className='edit_admin_icon_div admin_text_danger'>
                                                        <Icon.Trash />
                                                    </div>
                                                </div>
                                                <div className="col py-3 d-flex flex-column position-static">
                                                    <div className="">
                                                        <a href="" target="_blank">
                                                            <img
                                                                src={Promotion10}
                                                                alt="UserImg"
                                                                className="img-fluid news-list-img"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}
export default PromotionSiteAdmin;
