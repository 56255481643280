import React, { Suspense,useState, useEffect } from "react";
// import Header from "../Layout/header-admin";
import Sidebar from "../Layout/sidebar";
import BannerImg from "../images/home-banner.png";
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg";
import cupeLogo from "../images/sub-logo-cupe.jpg";
import DashboardNotification from "./dashboardNotification";
import MemberRequest from "./memberRequest";
import AdminTools from "./adminTools";
import Barchart from "./bar-chart";
import DonutChart from "./donutChart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let Header = React.lazy(() => import("../Layout/header-admin"))
// import { siteDetailsServices } from '../redux/reducers/servicesPermissionSlice';
// import Select from "react-select";
// import { useDispatch, useSelector } from 'react-redux';
function Dashboard() {
    
    useEffect(() => {
        setTimeout(() => {
        var element = document.getElementById("dashboard-menu");
        element.classList.add("active");
    }, 1000);  
    }, []);
  
    return (
        <div>
             <Suspense fallback={<div></div>}>
            <div className="container-fluid padding-wrapper">
                <Header></Header>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <div className="container-fluid">
                    <div className="banner-top-wrapper">
                        <img src={BannerImg} className="banner-top-img img-fluid"></img>
                        <img
                            src={cupeLogo}
                            className="banner-left-img"
                            // onClick={notify}
                        ></img>
                        <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                    </div>
                    <div className="row mx-0">
                        <div className="Sidebar-left">
                            <Sidebar />
                        </div>
                        <div className="Sidebar-right">
                            <div className="row m-0">
                                <div className="col-md-12 col-lg-4">
                                {/* {userCredential?.roleName === "SUPER_ADMIN" && */}
                                <div className='col-md-12 px-0'>
                                    {/* <Select
                                        // className="custom-select w-100"
                                        value={selectedSiteId}
                                        placeholder="Select website"
                                        options={newArray.map(item => ({
                                            value: item.id,
                                            label: item.name,
                                        }))}
                                        onChange={selectSite}
                                    /> */}
                                </div>
                           {/* }  */}
                                    <MemberRequest />

                                </div>
                                <DashboardNotification />
                                <AdminTools />

                                <div className="col-lg-12">
                                    <div className="card mb-3">
                                        <div className="card-body p-0">
                                            <ul className="list-group">
                                                <li className="list-group-item border-left-0 border-right-0 border-top-0 py-2">
                                                    <h5>Custom Union Member Analytics</h5>
                                                    <p className="text-danger mb-0">
                                                        <small>Collection of union members</small>
                                                    </p>
                                                </li>
                                                <div className="row p-3">
                                                    {/* {memberAnalyticsbyDOBArray&& */}
                                                    {/* <div className="col-lg-6 col-md-6">
                                                        <Barchart  />
                                                    </div> */}
                                                    {/* } */}
                                                        {/* {analyticsByUserProfileDOBArray&& */}
                                                    <div className="col-lg-6 col-md-6">
                                                        <DonutChart />
                                                    </div>
                                                    {/* } */}

                                                    {/* {analyticsActiveUserProfile && */}
                                                        {/* <div className="col-lg-6 col-md-6 mt-4">
                                                            <Barchart/>
                                                        </div> */}
                                                        {/* } */}
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Suspense>
        </div>
    );
}

export default Dashboard;
