import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import { ToastContainer, toast } from 'react-toastify';
import homeBanner from '../images/home-banner.webp'
import Logo from '../images/logo.png'

function BannerEdit() {
    useEffect(() => {
        var element1 = document.getElementById("home-vertical-tab");
        element1.classList.add("active");
    }, [])
    return (
        <div className="container-fluid">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"B"}
                theme="colored" />
            <div className='edit_admin_div'>
                <div className='edit_admin_maindiv'>
                    <div className='edit_admin_divtag'>
                    <div className='edit_image_div w-100'>
                            <img src={homeBanner} alt="logo" width="800" height="300" className='img-fluid' />                          
                        
                            <div className='edit_logo_div'>
                            <img src={Logo} alt="logo" width="800" height="300" className='img-fluid' />
                        </div>
                        </div>
                        <h1 className='edit_logo_text mt-4'>CUPE Demo</h1>
                        <h6 className='edit_logo_text edit_logo_text_sub'>WORKERS OF BRAMPTON</h6>
                    </div>
                    <div className='edit_admin_icon'>
                        <div className='edit_admin_icon_div' >
                            <Icon.Edit />
                        </div>
                    </div>
                    <div className='edit_admin_icon'>
                        <div className='edit_admin_icon_div' >
                            <Icon.Plus />
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerEdit;
