import React, { Suspense, useEffect, useState } from 'react'; // Added useState
import BannerImg from "../images/home-banner.webp";
import AboutImg from "../images/about.png";
import $ from 'jquery'
import MainBanner from "../images/main-banner.jpg";
import logo from '../images/logo.png';
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Footer from "./footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveData } from "../service";
let Header = React.lazy(() => import("./header"))

function Home() { 

    useEffect(() => {
        $("#home").addClass("active")
    }, []);

    const getBlogDataFn = () => { 
        // e.preventDefault();
        let flag = true
        var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var mobile = /^\d{10}$/;
        let firstName = $("#firstName").val();
        let lastName = $("#lastName").val();
        let email = $("#emailId").val();
        let phNumber = $("#phNumber").val();
        let unionName = $("#unionName").val();
        let messages = $("#messages").val();

        if (firstName === "") {
            flag = false
            $("#firstName").focus()
            toast.warn("Please enter first name.")
        }
        else if (lastName === "") {
            flag = false
            $("#lastName").focus()
            toast.warn("Please enter last name.")
        }
        else if (!email) {
            flag = false
            $("#emailId").focus()
            toast.warn("Please enter email id.")
        }
        else if (characters.test(email) == false) {
            flag = false
            $("#emailId").focus()
            toast.warn("Invalid email Id.")
        }
        else if (phNumber !== "" && phNumber.length != 10 && (!phNumber.match(/^[0-9]{10}$/))) {
                flag = false
                $("#phNumber").focus()
                toast.warn("Invalid phone number.")
        }
        else if (!messages) {
            flag = false
            $("#messages").focus()
            toast.warn("Please enter message.")
        }
        else if (flag === true) {
            const postData = {
                functionName: "saveSiteEnquiryDetails",
                siteEnquiryId: "",
                siteName: unionName,
                firstName: firstName,
                lastName: lastName,
                contactNumber: phNumber,
                emailId: email,
                comments: messages,
                createdOn: new Date().getTime(),
                status: "Open"
            };
            saveData(postData).then((data) => {
                if (data.success === "1") { 
                    toast.success("Thank you! Our support team will contact you soon....")
                    $("#firstName, #lastName, #emailId, #phNumber, #unionName, #messages").val("")
                    let datas = data.result
                    // console.log("datas---", datas);
                    const postData1 = {
                        functionName: datas.functionName,
                        memeberId: datas.memeberId,
                        jsonMailOptions: {
                            from: datas.jsonMailOptions.from,
                            to: datas.jsonMailOptions.to,
                            subject: datas.jsonMailOptions.subject,
                            html: datas.jsonMailOptions.html
                        }
                    } 
                    // console.log("postData1---", postData1);
                    saveData(postData1).then((data) => {
                        if (data.success === "1") { 
                        }
                    })
                }
            });

        }
    };
    return (
        <div>
            <Suspense fallback={<div></div>}>
                <div>
                    <section class="Mackenzie home-wrappers">
                        <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />

                        <Header></Header>
                        <div class="main-wrapper">
                            <div className='container banner-wrapper' id="equality-statement">
                                <div className='equality-wrapper row mx-0'>
                                    <div className='equality-content col-md-12 col-lg-5 mt-lg-0 mt-4'>
                                        {/* <h2 className='main-head'>
                                        A fully featured web application to manage your Union Membership
                                        </h2>
                                        <a className="read-more" href="/cupe-demo" style={{ textDecoration: 'none' }}>
                                            <button type="button" className="btn_equality">
                                                See Demo
                                            </button>
                                        </a> */}
                                          <h2 class="main-head-sub text-center">Let’s Talk</h2>
                                        <div className='form row'>
                                       
                                            <div class="col-md-6 form-label-group">
                                                 <input type="text" class="form-control" id="firstName" aria-describedby="emailHelp" placeholder='first Name*'/>
                                                <label for="exampleInputEmail1" className="form-label-text">First Name* </label>                                            
                                            </div>
                                            <div class="col-md-6 form-label-group">                                              
                                                <input type="text" class="form-control" id="lastName" aria-describedby="emailHelp" placeholder='Last Name*'/>
                                                <label for="exampleInputEmail1" className="form-label-text">Last Name* </label>
                                            </div>
                                            <div class="col-md-6 form-label-group">
                                                <input type="email" class="form-control" id="emailId" aria-describedby="emailHelp" placeholder='Email*'/>
                                                <label for="exampleInputEmail1" className="form-label-text">Email*</label>
                                            </div>
                                            <div class="col-md-6 form-label-group">
                                               
                                                <input type="text" class="form-control" id="phNumber" aria-describedby="emailHelp" maxLength={10} placeholder='Phone Number'/>
                                                <label for="exampleInputEmail1" className="form-label-text">Phone Number </label>
                                            </div>
                                            <div class="col-md-12 form-label-group">
                                                <input type="text" class="form-control" id="unionName" aria-describedby="emailHelp" placeholder='Uninon Name / Site Name'/>
                                                <label for="exampleInputEmail1" className="form-label-text">Union Name / Site Name </label>
                                            </div>
                                            <div class="col-md-12 form-label-group">
                                                <textarea class="form-control" id="messages" placeholder='Messages*'/>
                                                <label for="exampleInputEmail1" className="form-label-text">Message*</label>
                                            </div>
                                            <button type="submit" class="btn btn-primary btn-submit"
                                            onClick={()=> getBlogDataFn ()}>Submit</button>
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-7 quality_img'>
                                        <img className='img-fluid' src={MainBanner} width="500" height="500" alt="Equality image" title="Equality image" />
                                    </div>
                                </div>
                            </div>
                            <div class="about_wrapper">
                                <div class="container">
                                    <div class="about-wrapper row mx-0">
                                        <div class="about-content col-md-12 col-lg-6">
                                            <h2 class="main-head"><span class="main-head-btm">Web Application Features:
</span></h2>
                                            <p>
                                                <ul className='about-content-p'>
                                                    <li>Automated member calling, SMS texting & emailing</li>
                                                    <li>Member List Import</li>
                                                    <li>Grievance Application</li>
                                                    <li>Social Media Management</li>
                                                    <li>Union Member Services and updateable profile</li>
                                                    <li>Custom features available</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div class="form-section col-md-12 col-lg-6">
                                        {/* <h2 class="main-head-sub text-center">Let’s Talk</h2>
                                        <div className='form row'>
                                            <div class="col-md-6 form-group">
                                                <label for="exampleInputEmail1">First Name* </label>
                                                <input type="text" class="form-control" id="firstName" aria-describedby="emailHelp"/>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label for="exampleInputEmail1">Last Name* </label>
                                                <input type="text" class="form-control" id="lastName" aria-describedby="emailHelp" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label for="exampleInputEmail1">Email*</label>
                                                <input type="email" class="form-control" id="emailId" aria-describedby="emailHelp" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label for="exampleInputEmail1">Phone Number </label>
                                                <input type="text" class="form-control" id="phNumber" aria-describedby="emailHelp" maxLength={10}/>
                                            </div>
                                            <div class="col-md-12 form-group">
                                                <label for="exampleInputEmail1">Union Name / Site Name </label>
                                                <input type="text" class="form-control" id="unionName" aria-describedby="emailHelp" />
                                            </div>
                                            <div class="col-md-12 form-group">
                                                <label for="exampleInputEmail1">Messages*</label>
                                                <textarea class="form-control" id="messages"/>
                                            </div>
                                            <button type="submit" class="btn btn-primary btn-submit"
                                            onClick={()=> getBlogDataFn ()}>Submit</button>
                                        </div> */}
                                        <h2 className='main-head'>
                                        A fully featured web application to manage your Union Membership
                                        </h2>
                                        <a className="read-more" href="/cupe-demo" style={{ textDecoration: 'none' }}>
                                            <button type="button" className="btn_equality">
                                                See Demo
                                            </button>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </section>
                </div>
            </Suspense>
        </div>
    )
}

export default Home