import React, { Component } from "react";
import Chart from "react-apexcharts";

class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          data: [null, null, null, 470] // Only data for Category 4
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false // Set to false for vertical bars
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ["51+", "36-50", "18-35", "No DB"] // Four categories
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              '<span>' +
              ["Category 1", "Category 2", "Category 3", "Category 4"][
                dataPointIndex
              ] +
              '</span>' +
              "-" +
              '<span>' +
              series[seriesIndex][dataPointIndex] +
              '</span>' +
              '</div>'
            );
          }
        }
      }
    };
  }

  render() {
    return (
      <div className="col-md-12 card p-0">
        <>Analytics By Member DOB</>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={367}
        />
      </div>
    );
  }
}

export default Barchart;
