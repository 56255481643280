import React, { Suspense, useState, useEffect } from "react";
import usetIcon from "../images/user-placeholder.png";
import Sidebar from "./Layout/sidebar"
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg";
import { Link } from "react-router-dom";
let Header = React.lazy(() => import("./Layout/header-admin"))
function NotificationList() {


    return (
        <div className="container-fluid padding-wrapper">
            <Suspense fallback={<div></div>}>
            <Header></Header>
            <div className="container-fluid">
                <div className="banner-top-wrapper">
                    <img src={BannerImg} className="banner-top-img img-fluid"></img>
                    <img src={cupeLogo} className="banner-left-img"></img>
                    <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                </div>
                <div className="row mx-0">
                    <div className="Sidebar-left">
                        <Sidebar />
                    </div>
                    <div className="Sidebar-right">
                        <div className="col-md-12">
                            <h1 className="mainHead"> Notification Details </h1>
                            <hr className='mb-0'></hr>
                        </div>
                        <div className="col-md-12 p-md-0">
                            <div className="text-right mt-2">
                                <a className="small" >Read All Notifications</a>

                            </div>
                            <div className="">
                                <Link 
                                // to="/member/news-details"
                                    className="media notification-read text-muted
                                             border-bottom p-3 text-decoration-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <img className="bd-placeholder-img mr-2 rounded-circle" width="32" height="32" src={usetIcon} />
                                    {/* <img className="bd-placeholder-img mr-2 rounded-circle" width="32" height="32" src={"https://storedigfiles.s3.amazonaws.com/test/cupetheme/" + obj.imageName} /> */}

                                    <div className="media-body pb-3 mb-0 lh-125 border-gray">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <p className="mb-0"><strong className="text-dark">News Alert</strong></p>
                                            <small className="text-dark">
                                                Oct 13, 2023
                                            </small>
                                        </div>
                                        <span className="d-block text-dark">New Information has been posted in news feed</span>
                                    </div>
                                </Link>
                            </div>

                            <div className="">
                                <Link 
                                // to="/member/news-details"
                                    className="media notification-read text-muted
                                             border-bottom p-3 text-decoration-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <img className="bd-placeholder-img mr-2 rounded-circle" width="32" height="32" src={usetIcon} />
                                    {/* <img className="bd-placeholder-img mr-2 rounded-circle" width="32" height="32" src={"https://storedigfiles.s3.amazonaws.com/test/cupetheme/" + obj.imageName} /> */}

                                    <div className="media-body pb-3 mb-0 lh-125 border-gray">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <p className="mb-0"><strong className="text-dark">News Alert</strong></p>
                                            <small className="text-dark">
                                                Oct 13, 2023
                                            </small>
                                        </div>
                                        <span className="d-block text-dark">New Information has been posted in news feed</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </Suspense>
        </div>

    );
}

export default NotificationList;
