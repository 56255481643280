import React,{useEffect,useState} from "react";
import BannerImg from '../images/equality_bg01.webp';
// import Cupelogo from '../images/cupe.png';
// import { homePageListing } from '../redux/reducers/homePageSlice';
// import {useSelector } from 'react-redux';
const Banner = (bannerBottom) => {


  return (
    <div className="Bannerdiv Bannerdiv-equality mt-2 bg-img-section">
      <img src={BannerImg} alt="Banner" className="img-fluid equality-banner-img" width="900" height="500" style={{visibility:"hidden"}}/>

 {/* <img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/equality_bg01.webp" alt="Banner" class="img-fluid equality-banner-img" width="900" height="500" style="visibility: hidden;"> */}


      
      {/* <div className="banner-text">
        Cupe 831
      </div> */}
      <div className="banner-text-2">
        <div className="">
        EQUALITY STATEMENT
        </div>
        </div>
      {/* <div className="cupe-sub-logo">
        <img src={Cupelogo} alt="Cupelogo" className="img-fluid" width="144" height="144" />
      </div> */}
    </div>
  );
};

export default Banner;
