import React, { useEffect,useState } from "react";
import EqualityBottom from "../images/about.webp"

const BottomSection= () => {

        return (
            <div className="container bottomsection-wrapper">
                <div className="col-lg-12 col-md-12 bottom-left text-center">
                    <div className="bottom-left-img">
                        <img src={EqualityBottom} alt="Equality Statement" className="img-fluid equality-btm-imgg"  width="600" height="400"/>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 bottom-right">
                        <p className="bottom-para">CUPE’s policies and practices must reflect our commitment to equality. Members, staff and elected officers must be mindful that all sisters and brothers deserve dignity, equality and respect.</p>
                        <p className="bottom-para">Sometimes discrimination takes the form of harassment. Harassment means using real or perceived power to abuse, devalue or humiliate. Harassment s​hould not be treated as a joke. The uneasiness and resentment that it creates are not feelings that help us grow as a union.</p>
                        <p className="bottom-para">Discrimination and harassment focus on characteristics that make us different; and they reduce our capacity to work together on shared concerns such as decent wages, safe working conditions, and justice in the workplace, society and in our union.</p>
                    </div> */}
                </div>
            </div>
        );
    }
export default BottomSection;
