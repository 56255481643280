import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import { ToastContainer, toast } from 'react-toastify';
import homeBanner from '../images/about.webp'
import Logo from '../images/logo.png'

function BannerEdit() {
    useEffect(() => {
        var element1 = document.getElementById("home-vertical-tab");
        element1.classList.add("active");
    }, [])
    return (
        <div className="container-fluid">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"B"}
                theme="colored" />
            <div class="edit_admin_div">
                <div class="edit_admin_maindiv">
                    <div class="edit_admin_divtag">
                        <div class="edit_image_div">
                            <img src={homeBanner} alt="homeBanner" width="300" height="200" class="img-fluid mb-3" />
                        </div>
                        <h3 class="edit_adming_head">About US</h3>
                        <h4 class="edit_admin_subhead">CUPE Demo</h4>
                        <div>
                            <p>CUPE Demo represents close to 1200 full-time and temporary workers in the city of Brampton. Our members are responsible for the following services, tht improve the qualityof life for Brampton citizens</p>
                        </div>
                    </div>
                    <div class="edit_admin_icon">
                        <div class="edit_admin_icon_div">
                        <Icon.Edit />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerEdit;
