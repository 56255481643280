import React, { useState, useEffect } from "react";
import usetIcon from "../images/user-placeholder.png";
// import { useDispatch, useSelector } from 'react-redux';
// import { notificationListing, notificationRead } from '../redux/reducers/memberSlice';
import { Link } from "react-router-dom";
// import moment from 'moment';
function DashboardNotification() {
   

    return (
        <div className="col-md-6 col-lg-4 my-3 my-md-0">
            <div className="card mb-3">
                <div className="card-body p-0">
                    <ul className="list-group">
                        <li className="list-group-item border-left-0 border-right-0 border-top-0 py-2">
                            <h5>OVERVIEW</h5>
                            <p className="text-danger mb-0 small">Your recent notifications</p>
                        </li>
                        <div className="overview-box">
                           
                                <Link className="media text-muted p-2"
                                    // to={"/news-details/" + obj.notificationId}
                                    
                                // to={{
                                //     pathname: '/news-details',
                                //     state: { data: obj }
                                // }}
                                >
                                   
                                        <img className="bd-placeholder-img mr-2 rounded-circle" width="40" height="40" src={usetIcon} />
                                      
                                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                                        <strong className="d-block text-gray-dark">News Alert</strong>
                                        <small className="d-block text-muted" style={{ fontSize: "12px" }} >
                                        Oct 18, 2023
                                            {/* {moment(obj.createdOn).format('DD MMM YYYY')} */}
                                        </small>
                                        New Information has been posted in news feed
                                    </p>
                                </Link>
                                <Link className="media text-muted p-2"
                                    // to={"/news-details/" + obj.notificationId}
                                    
                                // to={{
                                //     pathname: '/news-details',
                                //     state: { data: obj }
                                // }}
                                >
                                   
                                        <img className="bd-placeholder-img mr-2 rounded-circle" width="40" height="40" src={usetIcon} />
                                      
                                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                                        <strong className="d-block text-gray-dark">News Alert</strong>
                                        <small className="d-block text-muted" style={{ fontSize: "12px" }} >
                                        Oct 18, 2023
                                            {/* {moment(obj.createdOn).format('DD MMM YYYY')} */}
                                        </small>
                                        New Information has been posted in news feed
                                    </p>
                                </Link>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DashboardNotification;
