import React, { Suspense,useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import Header from "../Layout/header-admin";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Sidebar from "../Layout/sidebar";
import BannerImg from "../images/home-banner.png";
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg";
import cupeLogo from "../images/sub-logo-cupe.jpg";
import { ToastContainer, toast } from 'react-toastify';
let Header = React.lazy(() => import("../Layout/header-admin"))
function Services() {
  useEffect(() => {
    setTimeout(() => {
    var element = document.getElementById("services-menu1");
    element.classList.add("active");
  }, 1000);  
  }, []);
  
  return (
    <div>
       <Suspense fallback={<div></div>}>
      <div className="container-fluid">
        <ToastContainer
          enableMultiContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          containerId={"K"}
          theme="colored"
        />
        <Header></Header>
        <div className="container-fluid">
          <div className="banner-top-wrapper">
            <img src={BannerImg} className="banner-top-img img-fluid"></img>
            <img src={cupeLogo} className="banner-left-img"></img>
            <img src={BannerImgBtm} className="banner-top-btmimg"></img>
          </div>
          <div className="row mx-0">
            <div className="Sidebar-left">
              <Sidebar />
            </div>
            <div className="Sidebar-right">
              <div className="card border-0">
                <div className="card-body px-0 py-0">
                  <div className="col-md-12">
                    <h1 className="mainHead">Services</h1>
                    <hr class="mb-0"></hr>
                  </div>
                  <div class="row custom-card m-0">
                    <div class="col-md-12 mb-4">
                     
                        <div className="service-wrapper mt-5">
                         
                             
                             
                                        <Link
                                          className="Srvice-list"
                                          to=""
                                        >
                                          <Icon.PhoneCall className="service-icon" />
                                          <span className="service-span">
                                            Call drop{" "}
                                          </span>
                                        </Link>
                                    
                                        <Link
                                          className="Srvice-list"
                                          to=""
                                        >
                                          <Icon.Mail className="service-icon" />
                                          <span className="service-span">
                                            Email blast{" "}
                                          </span>
                                        </Link>
                                     
                                        <Link
                                          className="Srvice-list"
                                          to=""
                                        >
                                          <Icon.MessageSquare className="service-icon" />
                                          <span className="service-span">
                                            Text blast{" "}
                                          </span>
                                        </Link>
                                   
                                        <Link
                                          className="Srvice-list"
                                          to=""
                                        >
                                          <Icon.Bell className="service-icon" />
                                          <span className="service-span">
                                            Push Notification{" "}
                                          </span>
                                        </Link>
                                     
                                  
                                        <Link
                                       
                                          className="Srvice-list"
                                          // to="/it-support"
                                        >
                                          <Icon.Cpu className="service-icon" />
                                          <span className="service-span" >
                                            IT Support{" "}
                                          </span>
                                        </Link>
                                   
                                    
                                    
                                  
                       
                          
                          {/* <Link className="Srvice-list" to="/">
                                                              <Icon.Speaker className="service-icon" />
                                                              <span className="service-span">Home </span>
                                                          </Link>
                                                          <Link className="Srvice-list" to="/">
                                                              <Icon.PhoneCall className="service-icon" />
                                                              <span className="service-span">Home </span>
                                                          </Link>
                                                          <Link className="Srvice-list" to="/">
                                                              <Icon.PhoneCall className="service-icon" />
                                                              <span className="service-span">Home </span>
                                                          </Link> */}
                        </div>
                     
                     
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Suspense>
    </div>
  );
}
export default Services;
