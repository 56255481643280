import React, { Suspense } from 'react'
import BannerImg from "../images/home-banner.webp"
import AboutImg from "../images/about.png"
import EqualityImg from "../images/equality_bg01.webp"
import logo from '../images/logo.png';
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
let Header = React.lazy(() => import("../layouts/header"))
let Footer = React.lazy(() => import("../layouts/footer"))

function home() {
    return (
        <div>
            <Suspense fallback={<div></div>}>
                <div>
                    <section class="Mackenzie home-wrappers">
                        <Header></Header>
                        <div class="main-wrapper">
                            <div class="banner-wrapper" style={{ backgroundImage: `url(${BannerImg})`, backgroundColor: "red", backgroundBlendMode: "multiply" }}>
                                <img src={BannerImg} width="100" height="100" alt="Banner" title="Banner" class="img-fluid banner-img" style={{ visibility: "hidden" }} />
                                <div class="container">
                                    <div class="container banner-content text-left">
                                        <h1 class="banner-head">CUPE DEMO</h1>
                                        <p class="banner-text">A FULLY FEATURED WEB APPLICATION
</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="about_wrapper">
                                <div class="container">
                                    <div class="about-wrapper row mx-0">
                                        <div class="about-img-div col-md-6">
                                            <img class="img-fluid" src={AboutImg} width="500" height="500" alt="About image" title="About image" />
                                        </div>
                                        <div class="about-content col-md-6">
                                            <h2 class="main-head text-white">About US<span class="main-head-btm text-uppercase">CUPE Demo</span></h2>
                                            <p class="about-content-p text-white mb-0">CUPE Demo represents close to 1200 full-time and temporary workers in the city of Brampton. Our members are responsible for the following services, tht improve the qualityof life for Brampton citizens</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid position-relative">
                                <div class="container">
                                    <div class="dvr-sector-wrapper">
                                        <h2 class="main-head text-center"> Diverse sectors </h2>
                                        <div class="dvr-sector-list">
                                            <div class="dvr-sector-sub">
                                                <div class="dvr-sector-img-div"><img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/co-services.png" class="img-fluid dvr-sector-img" width="250" height="250" alt="About image" title="About image" /></div>
                                                <div class="dvr-sector-content">
                                                    <h3 class="dvr-sector-head">Corporate Services</h3>
                                                    <p class="dvr-sector-p">Digital innovation and IT, FInance, Human Resources, Purchasing, strategic Communications, Culture and Events</p>
                                                </div>
                                            </div>
                                            <div class="dvr-sector-sub">
                                                <div class="dvr-sector-img-div"><img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/community.png" class="img-fluid dvr-sector-img" width="250" height="250" alt="About image" title="About image" /></div>
                                                <div class="dvr-sector-content">
                                                    <h3 class="dvr-sector-head">Community Services</h3>
                                                    <p class="dvr-sector-p">Community Safety and Well Being, Parks Maintenance and Forestry, Performing Arts, Recreation, Service Brampton</p>
                                                </div>
                                            </div>
                                            <div class="dvr-sector-sub">
                                                <div class="dvr-sector-img-div"><img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/engineering.png" class="img-fluid dvr-sector-img" width="250" height="250" alt="About image" title="About image" /></div>
                                                <div class="dvr-sector-content">
                                                    <h3 class="dvr-sector-head">Public Works and Engineering</h3>
                                                    <p class="dvr-sector-p">Building Design and Construction, Capital Works, Environment and Development Engineering, Facility Operations and Maintenance, Road Maintenance, Operations and Fleet</p>
                                                </div>
                                            </div>
                                            <div class="dvr-sector-sub">
                                                <div class="dvr-sector-img-div"><img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/building.png" class="img-fluid dvr-sector-img" width="250" height="250" alt="About image" title="About image" /></div>
                                                <div class="dvr-sector-content">
                                                    <h3 class="dvr-sector-head">Planning, Building and Economic Development</h3>
                                                    <p class="dvr-sector-p">Building, Economic Development, Development Services, Policy Planning, Strategic Planning Development, Transportation Planning, Urban Design</p>
                                                </div>
                                            </div>
                                            <div class="dvr-sector-sub">
                                                <div class="dvr-sector-img-div"><img src="https://cupefiles.s3.amazonaws.com/test/cupetheme/notes.webp" class="img-fluid dvr-sector-img" width="250" height="250" alt="About image" title="About image" /></div>
                                                <div class="dvr-sector-content">
                                                    <h3 class="dvr-sector-head">Legislative Services</h3>
                                                    <p class="dvr-sector-p">Animal Services, City Clerks Administration, Enforcement and By-Law Services, Insurance and Risk Management, Legal Services,Reality Services</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-11 ml-auto' id="equality-statement">
                                <div className='equality-wrapper row mx-0 align-items-center'>
                                    <div className='equality-content col-md-6'>
                                        <h2 className='main-head'>
                                            Equality Statement<span class="main-head-btm text-uppercase mt-2">A FORWARD-LOOKING APPROACH TO BUSINESS..</span>
                                        </h2>
                                        <p className='equality-content-p'>Union solidarity is based on the principle that union members are equal and deserve mutual respect at all levels. Any behaviour that creates conflict prevents us from working together to stengthen our union</p>
                                        <Link className="read-more" to="/equalityStatment" style={{ textDecoration: 'none' }}>
                                            <button type="button" className="btn_equality">
                                                Read More
                                                <Icon.ChevronRight className="ml-2" />
                                            </button>
                                        </Link>
                                    </div>
                                    <div className='col-md-6 quality_img'>
                                        <img className='img-fluid' src={EqualityImg} width="500" height="500" alt="Equality image" title="Equality image" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <Footer ></Footer>
                    </section>
                </div>
            </Suspense>
        </div>
    )
}

export default home