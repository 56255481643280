import React, { Suspense, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import LogoImg from '../images/logo.png';
import Sidebar from "./Layout/sidebar"
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
let Header = React.lazy(() => import("./Layout/header-admin"))


function Grievances() {
    useEffect(() => {
        setTimeout(() => {
            var element = document.getElementById("grievances-menu");
            element.classList.add("active");
        }, 1000);

    }, []);
    return (
        <div className="admin-wrapper container-fluid">
            <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
            <Suspense fallback={<div></div>}>
                <Header></Header>
                <div className="container-fluid">
                    <div className="banner-top-wrapper">
                        <img src={BannerImg} className="banner-top-img img-fluid"></img>
                        <img src={cupeLogo} className="banner-left-img"></img>
                        <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                    </div>
                    <div className="row mx-0 mt-5">
                        <div className="Sidebar-left">
                            <Sidebar />
                        </div>
                        <div className="Sidebar-right">
                            <div className="card border-0">
                                <div className="card-body px-0 py-0">
                                    <div className="row m-0">
                                        <div className="col-md-6">
                                            <h1 className="mainHead mb-0 mt-2">Grievance</h1>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <Link className="btn btn-sm btn-danger mt-2" to="" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>New Grievance </Link>
                                        </div>
                                    </div>
                                    <hr className="mb-0"></hr>
                                    <div className="custom-card m-0">
                                        <div className="tab-vertical mt-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <ul className="nav nav-tabs" id="myTab3" role="tablist" style={{ justifyContent: "unset" }}>
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="home-vertical-tab" data-toggle="tab" href="#home-vertical" role="tab" aria-controls="home" aria-selected="true" >Open grievance</a>
                                                        </li>
                                                        <li className="nav-item pl-3">
                                                            <a className="nav-link" id="profile-vertical-tab" data-toggle="tab" href="#profile-vertical" role="tab" aria-controls="profile" aria-selected="false" > Closed grievance </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card border-0">
                                                <div className="card-body px-0 py-0">
                                                    <div className="tab-content custom-card" id="myTabContent3">
                                                        <div className="tab-pane fade show active" id="home-vertical" role="tabpanel" aria-labelledby="home-vertical-tab">
                                                            <div className="row m-0">
                                                                <div className="row w-100 mt-4 mx-0 alignItemCenter">
                                                                    <div className="col-md-4">
                                                                        <div className='form-group'>
                                                                            <Select
                                                                                placeholder="Filter By"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="col-md-12 filter-wrapper mb-0">
                                                                            <input className="form-control search-text" id="myInputOpen" type="text" placeholder="Search Text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className='form-group'>
                                                                            <span className="btn btn-dark " id='clearFilterOpen' type="submit"  onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                                                                                Clear Filter
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="table-responsive custom-table-responsive mt-4 px-3">
                                                                    <table className="table custom-table table-striped">
                                                                        <thead>
                                                                            <tr className="custom-th">
                                                                                <th scope="col" style={{ width: "40px" }}>
                                                                                    <div className="form-check mt-1">
                                                                                        <input type="checkbox" className="form-check-input" id="selectAll" />
                                                                                        <label className="form-check-label" for="selectAll">&nbsp;</label>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope="col">Title</th>
                                                                                <th scope="col">Employer Name</th>
                                                                                <th scope="col">Submit Date</th>
                                                                                <th scope="col">Union Member Details </th>
                                                                                <th scope="col">Details </th>

                                                                                <th scope="col">To close grievance</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr scope="row">
                                                                                <td scope="row">
                                                                                    <div className="form-check mt-1">
                                                                                        <input type="checkbox" className="form-check-input" id="selectcheck" />
                                                                                        <label className="form-check-label" for="selectcheck">&nbsp;</label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>Test demo</td>
                                                                                <td>Clara Antony</td>
                                                                                <td>17-10-2023</td>
                                                                                <td>Richard Davidson</td>
                                                                                <td>Test demo</td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-between align-items-center position-relative">
                                                                                        <div className="custom-control custom-switch mb-3">
                                                                                            <input type="checkbox" className="custom-control-input" />
                                                                                            <label className="custom-control-label" ></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="spacer"><td colSpan="6"></td></tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="tab-pane fade" id="profile-vertical" role="tabpanel" aria-labelledby="profile-vertical-tab">
                                                            <div className=" m-0">
                                                                <div className="row w-100 mt-4 mx-0">
                                                                    <div className="col-md-4">
                                                                        <div className='form-group'>
                                                                            <Select
                                                                                placeholder="Filter By"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="col-md-12 filter-wrapper mb-0">
                                                                            <input className="form-control search-text" id="myInputClose" type="text" placeholder="Search Input" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className='form-group'>
                                                                            <span className="btn btn-dark " id='clearFilterClose' type="submit" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                                                                                Clear Filter
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive custom-table-responsive mt-4 px-3">

                                                                    <table className="table custom-table table-striped">
                                                                        <thead>
                                                                            <tr className="custom-th">
                                                                                <th scope="col" style={{ width: "40px" }}>
                                                                                    <div className="form-check mt-1">
                                                                                        <input type="checkbox" className="form-check-input" id="selectAll" />
                                                                                        <label className="form-check-label" for="selectAll">&nbsp;</label>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope="col">Title</th>
                                                                                <th scope="col">Employer Name</th>
                                                                                <th scope="col">Submit Date</th>
                                                                                <th scope="col">Union Member Details </th>
                                                                                <th scope="col">Details </th>
                                                                                <th scope="col"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr scope="row">
                                                                                <td scope="row">
                                                                                    <div className="form-check mt-1">
                                                                                        <input type="checkbox" className="form-check-input" id="selectcheck" />
                                                                                        <label className="form-check-label" for="selectcheck">&nbsp;</label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>Test demo1</td>
                                                                                <td>Isabella Mia</td>
                                                                                <td>17-10-2023</td>
                                                                                <td>Liam Jacob</td>
                                                                                <td>Test demo	</td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-between align-items-center position-relative">
                                                                                        <span className="news-icon-top">
                                                                                            <div className="dropdown dropdown-member">
                                                                                                <Icon.MoreVertical className="ml-2 text-black" type="button" data-toggle="dropdown" aria-expanded="false" size={14} />
                                                                                                <div className="dropdown-menu">
                                                                                                    <Link className="dropdown-item" to="/profile">
                                                                                                        Profile
                                                                                                    </Link>
                                                                                                    <span className="dropdown-item" to="/profile" >
                                                                                                        Delete
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="spacer"><td colSpan="6"></td></tr>

                                                                        </tbody>
                                                                    </table>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default Grievances;
