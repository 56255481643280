import React, { Suspense, useState, useEffect } from "react";
import News from "../images/news-img.jpg";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import Sidebar from "../Layout/sidebar"
let Header = React.lazy(() => import("../Layout/header-admin"))
function NewsDetailsSiteAdmin() {

  
    return (
        <div className="container-fluid">
             <Suspense fallback={<div></div>}>
            <Header></Header>
            <div className="container-fluid">
                <div className="banner-top-wrapper">
                    <img src={BannerImg} className="banner-top-img img-fluid"></img>
                    <img src={cupeLogo} className="banner-left-img"></img>
                    <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                </div>
                <div className="mt-2">
                    <small>
                        <Link to="/member/notification-list" className="text-secondary" style={{ color: "#000", textDecoration: "none" }}>
                            <Icon.ChevronLeft fill="bg-gray" type="button" data-toggle="dropdown" aria-expanded="false" size={18} />
                            Back To Notification Details
                        </Link>
                    </small>
                </div>
                <div className="row mx-0 mt-4">
                    <div className="Sidebar-left">
                        <Sidebar />
                    </div>
                    <div className="Sidebar-right">
                            <div className="col">
                                <div className="">
                                        <img src={News} alt="UserImg" className="img-fluid" />
                                   
                                </div>
                                <div className="media text-muted pt-3 my-3">
                                    <img className="mr-4 rounded img-fluid" width="70" height="32" src={Logo}></img>

                                    <div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <p className="mb-0 text-break">
                                                <strong className="text-dark">DemoCupeSiteAdmin</strong>
                                            </p>
                                        </div>
                                        <span className="d-block">
                                         Oct 13, 2023
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h4>News Alert</h4>
                                    <p >New Information has been posted in news feed</p>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            </Suspense>
        </div>
    );
}

export default NewsDetailsSiteAdmin;
