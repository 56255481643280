import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

function AdminTools() {



    return (
        <div className="col-md-6 col-lg-4">
            <div className="card">
                <div className="border-left-0 border-right-0 border-top-0 border px-4 py-2">
                    <h5>Admin Tools</h5>
                    <p className="text-danger mb-0 small">Collection of administrative services</p>
                </div>
                <div className="card-body admin-tool-box">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column align-items-center mb-3 ">
                            <Link className="nav-link text-center" to="">
                                <Icon.Home className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Customize Home Page</h6>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center mb-3 inactive">
                            <Link className="nav-link text-center" to="/domain-filter">
                                <Icon.AtSign className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Domain Filter</h6>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center mb-3 inactive">
                            <Link className="nav-link text-center" to="/registration-inputs">
                                <Icon.LogIn className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Register Inputs</h6>
                            </Link>
                        </div>

                        <div className="col-md-6 d-flex flex-column align-items-center mb-3">
                            <Link className="nav-link text-center" to="">
                                <Icon.Instagram className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Social Accounts</h6>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center mb-3 inactive">
                            <Link className="nav-link text-center" to="/customize-tabs">
                                <Icon.Layout className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Customize Tabs</h6>
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center mb-3 inactive">
                            <Link className="nav-link text-center" to="/grievances-inputs">
                                <Icon.Clock className="text-danger" />
                                <h6 className="mt-2 text-dark" style={{ fontSize: "12px" }}>Grievances Inputs</h6>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminTools;
