import React, { Component } from "react";
import BannerImg from '../images/BunchBerry-contactBanner.png';
import * as Icon from "react-feather";


class Banner extends Component {
    render() {
        return (

            <div className="Bannerdiv Bannerdiv-contact mt-2">
                <img src={BannerImg} alt="Banner" className="img-fluid img-fullwidth Bannerdiv-contact-img" width="1200" height="400" />
                <div className="bannerdiv-contact-text">
                    <div className="banner-text-2">
                        <span className="get_in_theme_color">Contacts</span>
                    </div>
                    <div className="banner-text-3">
                        <span className="banner-phone-text">
                            <Icon.MapPin />
                            <span>615 Brand Road, Humboldt</span>
                        </span>
                        <span className="banner-phone-text">
                            <Icon.Phone />
                            <span>999-789-0234</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
export default Banner;
