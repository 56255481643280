import React, { useState } from "react";
import UserImg from "../images/user-placeholder.png";
import { ToastContainer, toast } from 'react-toastify';

function ExecutiveList() {
  
  return (
    <div className="">
      <ToastContainer
        enableMultiContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"V"}
        theme="colored" />
  <div class="card-body">
   <div class="row">
      <div class="col-6">
         <h1 class="mainHead text-uppercase my-md-0 mt-2">Executive</h1>
      </div>
      <div class="col-6">
         <div class="row m-0 justify-content-end align-items-center">
            <button data-toggle="modal" data-target="#addExecutiveModal" class="btn btn-dark btn-sm col-7  col-md-3" type="submit">Add</button>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-style ml-3">
               <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
            </svg>
         </div>
      </div>
   </div>
   <div class="">
      <div class="media text-muted pt-3">
         <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2" ><strong class="d-block text-gray-dark card-subnames"> William</strong><small></small><span class="post-title"></span>
         <div><a href=""> William@gmail.com</a></div>
         </p>
      </div>
      <div class="media text-muted pt-3">
      <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2"><strong class="d-block text-gray-dark card-subnames"> Michael David</strong><small></small><span class="post-title"></span>
         <div><a href=""> Michaeldavid@gmail.com</a></div>
         </p>
      </div>
      <div class="media text-muted pt-3">
      <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2" ><strong class="d-block text-gray-dark card-subnames"> Jack Antony</strong><small></small><span class="post-title"></span>
         <div><a href=""> Jackantony@gmail.com</a></div>
         </p>
      </div>
      <div class="media text-muted pt-3">
      <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2" ><strong class="d-block text-gray-dark card-subnames"> Clara Lucas</strong><small></small><span class="post-title"></span>
         <div><a href=""> Claralucas@gmail.com</a></div>
         </p>
      </div>
      <div class="media text-muted pt-3">
      <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2"><strong class="d-block text-gray-dark card-subnames"> Harry Oliver</strong><small></small><span class="post-title"></span>
         <div><a href=""> Harryoliver@gmail.com</a></div>
         </p>
      </div>
      <div class="media text-muted pt-3">
      <img src={UserImg} alt="UserImg" width="60"/>
         <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray ml-2" ><strong class="d-block text-gray-dark card-subnames"> George Glenn </strong><small></small><span class="post-title">president</span>
         <div><a href=""> Georgeglenn@gmail.com</a></div>
         </p>
      </div>
   </div>
</div>

    </div>

  );
}

export default ExecutiveList;
