import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import service1 from '../images/co-services.png'
import service2 from '../images/community.png'
import service3 from '../images/engineering.png'
import service4 from '../images/building.png'
import service5 from '../images/notes.png'
import { ToastContainer, toast } from 'react-toastify';

function DiversSectorsEdit() {


    return (
        <div className="container-fluid">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"D"}
                theme="colored" />
            <div class="edit_admin_div">
   <div class="edit_admin_maindiv">
      <div class="edit_admin_divtag">
         <h3 class="edit_ading_head">Diverse Sectors</h3>
         <div class="row mt-3 mt-md-5">
            <div class="col-lg-4 col-md-6 mb-3">
               <div class="card h-100">
                  <div class="card-body">
                     <div class="edit_image_div">
                        <img src={service1} width="300" height="200" alt="co services1" class="img-fluid mb-3"/>

                        </div>
                     <h5>Corporate Services</h5>
                     <p>
                     <div>
                        <p>Digital innovation and IT, FInance, Human Resources, Purchasing, strategic Communications, Culture and Events</p>
                     </div>
                     </p>
                  </div>
                  <div class="edit_admin_icon">
                     <div class="edit_admin_icon_div">
                        <Icon.Edit />
                     </div>
                     <div class="edit_admin_icon_div admin_text_danger">
                        <Icon.Trash />
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
               <div class="card h-100">
                  <div class="card-body">
                     <div class="edit_image_div">
                        <img src={service2} width="300" height="200" alt="Community Services" class="img-fluid mb-3"/></div>
                     <h5>Community Services</h5>
                     <p>
                     <div>
                        <p>Community Safety and Well Being, Parks Maintenance and Forestry, Performing Arts, Recreation, Service Brampton</p>
                     </div>
                     </p>
                  </div>
                  <div class="edit_admin_icon">
                     <div class="edit_admin_icon_div">
                        <Icon.Edit />
                     </div>
                     <div class="edit_admin_icon_div admin_text_danger">
                        <Icon.Trash />
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
               <div class="card h-100">
                  <div class="card-body">
                     <div class="edit_image_div"><img src={service3} width="300" height="200" alt="Public Works" class="img-fluid mb-3"/></div>
                     <h5>Public Works and Engineering</h5>
                     <p>
                     <div>
                        <p>Building Design and Construction, Capital Works, Environment and Development Engineering, Facility Operations and Maintenance, Road Maintenance, Operations and Fleet</p>
                     </div>
                     </p>
                  </div>
                  <div class="edit_admin_icon">
                     <div class="edit_admin_icon_div">
                        <Icon.Edit />
                     </div>
                     <div class="edit_admin_icon_div admin_text_danger">
                         <Icon.Trash />
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
               <div class="card h-100">
                  <div class="card-body">
                     <div class="edit_image_div"><img src={service4} width="300" height="200" alt="Planning" class="img-fluid mb-3"/></div>
                     <h5>Planning, Building and Economic Development</h5>
                     <p>
                     <div>
                        <p>Building, Economic Development, Development Services, Policy Planning, Strategic Planning Development, Transportation Planning, Urban Design</p>
                     </div>
                     </p>
                  </div>
                  <div class="edit_admin_icon">
                     <div class="edit_admin_icon_div">
                        <Icon.Edit />
                     </div>
                     <div class="edit_admin_icon_div admin_text_danger">
                        <Icon.Trash />
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
               <div class="card h-100">
                  <div class="card-body">
                     <div class="edit_image_div"><img src={service5} width="300" height="200" alt="Legislative" class="img-fluid mb-3"/></div>
                     <h5>Legislative Services</h5>
                     <p>
                     <div>
                        <p>Animal Services, City Clerks Administration, Enforcement and By-Law Services, Insurance and Risk Management, Legal Services,Reality Services</p>
                     </div>
                     </p>
                  </div>
                  <div class="edit_admin_icon">
                     <div class="edit_admin_icon_div">
                     <Icon.Edit />
                     </div>
                     <div class="edit_admin_icon_div admin_text_danger">
                     <Icon.Trash />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="edit_admin_icon">
         <div class="edit_admin_icon_div">
         <Icon.Edit />
         </div>
      </div>
   </div>
</div>
        </div>
    );
}

export default DiversSectorsEdit;
