import React, { Component, useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import servicesImg from "../../images/services.png"
import noteImg from "../../images/note.png"
import membersImg from "../../images/members.png"
import socialMedia from "../../images/Social-media-icon.png"
import contractImg from "../../images/contract.png"
export default function Sidebar() {
  
 
  return (

    <div className="d-flex flex-column flex-shrink-0 bg-light sidebar">

      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          
        <li>
          <Link className="nav-link border-bottom" id="services-menu" to="/member/profile">
            <img src={servicesImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Profile </span>
          </Link>
        </li>
         
        <li>
          <Link className="nav-link border-bottom" id="promotion-menu" to="/member/promotion">
            <img src={membersImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Promotion </span>
          </Link>
        </li>
      <li>
          <Link className="nav-link border-bottom" id="contract-menu" to="/member/contract">
            <img src={contractImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Contract</span>
          </Link>
        </li>
        {/* <li>
          <Link className="nav-link border-bottom" id="grievances-menu" to="/member/grievance">
            <img src={noteImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Grievances </span>
          </Link>
        </li> */}
        <li>
          <Link className="nav-link border-bottom" id="socialmedia-menu" to="/member/social-media">
            <img src={socialMedia} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Social Media </span>
          </Link>
        </li>
        
      </ul>
    </div>
  );
}

