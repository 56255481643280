import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
// import Input from "react-select/dist/declarations/src/components/Input";
function PersonalInfo(props) {

    return (
        <div className="needs-validation" novalidate="">
          <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
            <div className="col-md-12 mb-3">
                <div class="card-body row">
                
                    <div className='form-label-group col-md-12'  >
                        <input
                            type="text"
                            id='firstName'
                            className="form-control"
                            placeholder="First Name"
                            required=""
                            name="firstName"
                        />
                        <label className="form-label-text" for="userName">First Name<span className="red ml-1">*</span></label>
                        
                    </div>
                    <div className='form-label-group  col-md-12  '>
                        {/* <p className="mb-0">Last Name</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            required=""
                            id='lastName'
                            name="lastName"
                            
                        />
                        <label className="form-label-text" for="lastName">Last Name<span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Middle Name</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            required=""
                            id="middleName"
                            name="middleName"
                        />
                        <label className="form-label-text" for="middleName">Middle Name</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Maiden Name</p> */}
                        <input
                           
                            type="text"
                            className="form-control"
                            placeholder="Maiden Name"
                            required=""
                            name="maidenName"
                            id="maidenName"

                        />
                        <label className="form-label-text" for="maidenName">Maiden Name</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  '>
                        {/* <p className="mb-0">Common Name</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Common Name"
                            required=""
                            id="commonName"
                            name="commonName"
                        />
                        <label className="form-label-text" for="commonName">Common Name</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Gender</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Gender"
                            required=""
                            name="Gender"
                            id="Gender"
                        />
                        <label className="form-label-text" for="gender">Gender<span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  '>
                        {/* <p className="mb-0">Username</p> */}
                        <input
                            //    disabled={DisableFlag}
                            disabled={true}

                            type="text"
                            className="form-control"
                            placeholder="User Name"
                            required=""
                            name="userName"
                            id="userName"
                        />
                        <label className="form-label-text" for="userName">Username</label>
                       
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Status</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Status"
                            required=""
                            name="status"
                            id="status"
                        />
                        <label className="form-label-text" for="status">Status</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Date Of Birth</p> */}
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Date Of Birth"
                            required=""
                            name="dateOfBirth"
                            id="dateOfBirth"
                        />
                        <label className="form-label-text" for="dateOfBirth">Date Of Birth<span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  '>
                        {/* <p className="mb-0">Personal Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Personal email"
                            required=""
                            name="personalEmail"
                            id="personalEmail"
                        />
                        <label className="form-label-text" for="personalEmail">Personal Email<span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  '>
                        {/* <p className="mb-0">Union Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Union email"
                            required=""
                            name="unionEmail"
                            id="unionEmail"
                        />
                        <label className="form-label-text" for="unionEmail">Union Email</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">Steward Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Steward Email"
                            required=""
                            name="stewardEmail"
                            id="stewardEmail"
                        />
                        <label className="form-label-text" for="stewardEmail">Steward Email</label>
                       
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            
                            type="text"
                            className="form-control"
                            placeholder="CB Email"
                            required=""
                            
                            name="cbEmail"
                            id="cbEmail"
                        />
                        <label className="form-label-text" for="cbEmail">CB Email</label>
                     
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cell Phone"
                            required=""
                            name="cellPhone"
                            id="cellPhone"
                        />
                        <label className="form-label-text" for="cellPhone">Cell Phone <span className="red ml-1">*</span></label>
                        
                    </div>
                    <div className='form-label-group col-md-12  '  >
                        {/* <p className="mb-0">Home Phone</p> */}
                        <input
                            
                            type="number"
                            className="form-control"
                            placeholder="home phone"
                            required=""
                            maxLength={12}
                            name="homePhone"
                            id="homePhone"

                        />
                        <label className="form-label-text" for="homePhone">Home Phone</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            required=""
                            name="address"
                            id="address"
                        />
                        <label className="form-label-text" for="Address">Address<span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            required=""
                            name="city"
                            id="city"
                        />
                        <label className="form-label-text" for="city">City</label>
                       
                    </div>
                    <div className='form-label-group  col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Province"
                            required=""
                            
                            name="province"
                            id="province"
                        />
                        <label className="form-label-text" for="province">Province</label>
                        
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Postal Code"
                            required=""
                            
                            name="postalCode"
                            id="postalCode"
                        />
                        <label className="form-label-text" for="postalCode">Postal Code <span className="red ml-1">*</span></label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className='form-label-group col-md-12  ' >
                        {/* <p className="mb-0">CB Email</p> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="IndegenousStatus"
                            required=""
                            name="indegenousStatus"
                            id="indegenousStatus"
                        />
                        <label className="form-label-text" for="indegenousStatus">IndegenousStatus</label>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-12 text-right"  >
                 

                            <button className="btn btn-dark col-md-3 col-lg-2 m-2" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                               Edit
                            </button>
                          
                            <span>
                                {/* <button className="btn btn-dark col-md-2 m-2" onClick={(e) => submitClick(e.preventDefault(), editOrSaveBotton)} >
                                    {editOrSaveBotton}
                                </button> */}
                                <button className="btn btn-outline-dark col-md-3 col-lg-2 m-2" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                                    CANCEL
                                </button>
                            </span>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalInfo;
