import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
function Notification(props) {





    return (
        <div className="align-items-center pl-3">
<ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
            <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" name="allow_call_drop" />
                <label className="custom-control-label" for="customSwitch1">Allow Call Drops</label>
            </div>
            <div className="custom-control custom-switch">
                <input type="checkbox" name="allow_text_message" className="custom-control-input" id="customSwitch2" />
                <label className="custom-control-label" for="customSwitch2">Allow Text Messages</label>
            </div>
            <div className="custom-control custom-switch">
                <input type="checkbox" name="allow_push_notification" className="custom-control-input" id="customSwitch3" />
                <label className="custom-control-label" for="customSwitch3">Allow Call Drops</label>
            </div>
            <div className="custom-control custom-switch">
                <input type="checkbox" name="allow_email" className="custom-control-input" id="customSwitch4" />
                <label className="custom-control-label" for="customSwitch4">Allow Push Notifications</label>
            </div>
            <div className="btn btn-wrapper text-right" >
                <button className="btn btn-outline-dark col-md-3 col-lg-2 ml-2" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                    CANCEL
                </button>
                <button className="btn btn-dark col-md-3 col-lg-2 m-2"  onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                    SUBMIT
                </button>
            </div>
        </div>

    );
}

export default Notification;
