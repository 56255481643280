import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IndexHome from "./index/home"
import Home from "./home/home"
import EqualityStatment from "./equalityStatement/equalityStatement";
import NewsSection from "./news/newsSection";
import Contact from "./contact/contact";
import Promotion from "./Member/promotion"
import PromotionSiteAdmin from "./site-admin/promotion"
import SocialMeList from './site-admin/social-media';
import Union from './site-admin/executive';
import Ads from './site-admin/ads';
import NotificationListSiteAdmin from "./site-admin/notification-list"
import NewsDetailsSiteAdmin from "./site-admin/news-details"
import ResetPasswordSiteAdmin from "./site-admin/reset-password"
import ProfileSiteAdmin from "./site-admin/profile"
import ContractSiteAdmin from "./site-admin/contract"
import Dashboard from "./site-admin/dashboard"
import GrievanceSiteAdmin from "./site-admin/grievance"
import Member from "./site-admin/member"
import Contract from "./Member/contract"
import Grievance from "./Member/grievance"
import SocialMeListMember from "./Member/social-media"
import Profile from "./Member/profile"
import NotificationList from "./Member/notification-list"
import NewsDetails from "./Member/news-details"
import ResetPassword from "./Member/reset-password"
import Services from "./site-admin/services"
import IndexAbout from "./index/about"
import IndexContact from "./index/contact"
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexHome />} />
      <Route path="/cupe-demo" element={<Home />} />
      <Route path="/equalityStatment" element={<EqualityStatment />} />
      <Route path="/news" element={<NewsSection />} /> 
      <Route path="/contact" element={<Contact />} /> 
      <Route path="/member/promotion" element={<Promotion />} />
      <Route path="/site-admin/promotion" element={<PromotionSiteAdmin />} />
      <Route path="/site-admin/social-media" element={<SocialMeList />} />
      <Route path="/site-admin/ads" element={<Ads />} />
      <Route path="/site-admin/member" element={<Member />} />
      <Route path="/site-admin/union" element={<Union />} />
      <Route path="/site-admin/notification-list" element={<NotificationListSiteAdmin />} />
      <Route path="/site-admin/news-details" element={<NewsDetailsSiteAdmin />} />
      <Route path="/site-admin/reset-password" element={<ResetPasswordSiteAdmin />} />
      <Route path="/site-admin/profile" element={<ProfileSiteAdmin />} />
      <Route path="/site-admin/dashboard" element={<Dashboard />} />
      <Route path="/site-admin/contract" element={<ContractSiteAdmin />} />
      <Route path="/site-admin/grievance" element={<GrievanceSiteAdmin />} />
      <Route path="/site-admin/services" element={<Services />} />
      <Route path="/member/contract" element={<Contract />} />
      <Route path="/member/grievance" element={<Grievance />} />
      <Route path="/member/social-media" element={<SocialMeListMember />} />
      <Route path="/member/profile" element={<Profile />} />
      <Route path="/member/notification-list" element={<NotificationList />} />
      <Route path="/member/news-details" element={<NewsDetails />} />
      <Route path="/member/reset-password" element={<ResetPassword />} />
      <Route path="/about" element={<IndexAbout />} />
      <Route path="/contactus" element={<IndexContact />} />
			</Routes>
		</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
