import React, { Component } from "react";
import Chart from "react-apexcharts";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 41, 17, 15],
      options: {
        chart: {
          type: 'donut',
        },
        labels: ['Complete Contact', 'Personal Email', 'Union Email', 'Home Phone', 'Cell Phone'], // Change these labels
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }

  render() {
    return (
      <div id="chart" className="col-md-12 card p-0">
        <Chart options={this.state.options} series={this.state.series} type="donut" />
      </div>
    );
  }
}

export default DonutChart;
