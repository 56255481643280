import React, { useState, useEffect, Suspense } from "react";
import Logo from "../images/logo.png";
import User from "../images/user_social.avif";
// Import Header and Footer components using React.lazy
let Header = React.lazy(() => import("../layouts/header"));
let Footer = React.lazy(() => import("../layouts/footer"));

function NewsSection() {
    return (
        <div className="footer_white news-wrappers">
            <Suspense fallback={<div>Loading...</div>}>
                <div className="container-fluid outer-padding news-wrapper header-position">
                    {/* Render the Header component */}
                    <Header />
                    <div className="card-columns mt-5 container card_columns_div">
                        {/* News Card 1 */}
                        <div className="card border flex-md-row mb-4 h-md-250 position-relative card-div">
                            <div className="col d-flex flex-column card-div-sub">
                                <div className="d-flex justify-content-between align-items-center py-2">
                                    <div className="logo-news">
                                        <img src={Logo} alt="" className="img-fluid" width={70} height={40} style={{ width: "70px" }} />
                                    </div>
                                    <small className="text-muted ml-auto">2 days ago</small>
                                </div>
                                <div className="news-content">
                                    <p>
                                        {/* News content with an image */}
                                        <div>
                                            <img src={User} alt="User" className="img-fluid" width="500" height="500" />
                                            <p>Australia's intelligence chief has said he had no reason to dispute Canadian Prime Minister Justin Trudeau's claims on India linked to the death of terrorist Hardeep Singh Nijjar</p>
                                            <a href="http://" target="_blank" rel="noopener noreferrer">'No reason to dispute' Canada's claim against India: Australian intel chief</a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* News Card 2 */}
                        <div className="card border flex-md-row mb-4 h-md-250 position-relative card-div">
                            <div className="col d-flex flex-column card-div-sub">
                                <div className="d-flex justify-content-between align-items-center py-2">
                                    <div className="logo-news">
                                        <img src={Logo} alt="" className="img-fluid" width={70} height={40} style={{width: "70px"}} />
                                    </div>
                                    <small className="text-muted ml-auto">2 days ago</small>
                                </div>
                                <div className="news-content">
                                    <p>
                                        {/* News content for Card 2 */}
                                        <div>
                                            <img src={User} alt="User" className="img-fluid" width="500" height="500" />
                                            <p>Australia's intelligence chief has said he had no reason to dispute Canadian Prime Minister Justin Trudeau's claims on India linked to the death of terrorist Hardeep Singh Nijjar</p>
                                            <a href="http://" target="_blank" rel="noopener noreferrer">'No reason to dispute' Canada's claim against India: Australian intel chief</a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* News Card 3 */}
                        <div className="card border flex-md-row mb-4 h-md-250 position-relative card-div">
                            <div className="col d-flex flex-column card-div-sub">
                                <div className="d-flex justify-content between align-items-center py-2">
                                    <div className="logo-news">
                                        <img src={Logo} alt="" className="img-fluid" style={{ width: "70px"}} width={70} height={40} />
                                    </div>
                                    <small className="text-muted ml-auto">2 days ago</small>
                                </div>
                                <div className="news-content">
                                    <p>
                                        {/* News content for Card 3 */}
                                        <div>
                                            <img src={User} alt="User" className="img-fluid" width="500" height="500" />
                                            <p>Australia's intelligence chief has said he had no reason to dispute Canadian Prime Minister Justin Trudeau's claims on India linked to the death of terrorist Hardeep Singh Nijjar</p>
                                            <a href="http://" target="_blank" rel="noopener noreferrer">'No reason to dispute' Canada's claim against India: Australian intel chief</a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* You can add more news cards as needed */}
                    </div>
                </div>
                {/* Render the Footer component */}
                <Footer />
            </Suspense>
        </div>
    );
}

export default NewsSection;
