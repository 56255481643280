import React, { Suspense, useEffect, useState } from 'react'; 
import $ from 'jquery'
import ContactBanner from "../images/contact-banner.png";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Footer from "./footer";
let Header = React.lazy(() => import("./header"))

function Contact() { 

    useEffect(() => {
        $("#contact").addClass("active")
        
    }, []);

    return (
        <div>
            <Suspense fallback={<div></div>}>
                <div>
                    <section class="Mackenzie contact-wrappers">

                        <Header></Header>
                        <div class="main-wrapper">
                            <div className='container banner-wrapper' id="equality-statement">
                                <div className='equality-wrapper row mx-0'>
                                    <div className='equality-content col-md-12 col-lg-4'>
                                        <h2 className='main-head'>
                                        Contact Us
                                        </h2>
                                        <div className="banner-text-3">
                                            <span className="banner-phone-text">
                                                <Icon.Mail/>
                                                {/* <span> 3599 Mesa Vista Drive</span> */}
                                                <span> Cupe@tempest-solutions.com</span>
                                            </span>
                                            <span className="banner-phone-text">
                                                <Icon.Phone/>
                                                <span>(416) 286-4005</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-8 quality_img quality_img_contact px-0'>
                                        <img className='img-fluid' src={ContactBanner} width="500" height="500" alt="Equality image" title="Equality image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </section>
                   
                </div>
            </Suspense>
            <div className='footer-margin'>
                            <Footer />
                        </div>
        </div>
    )
}

export default Contact