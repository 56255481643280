import React from "react";

function Executive() {
    return (
        <div className="executive_div">
            <div className="container Executivediv">
                <h2 className="main-head mainhead-contact">Executives</h2>
                <div className="ExecutiveOuter">
                    <div className="executiveBox-inline">
                        <div className="executiveBox">
                            <div className="executiveBox-right">
                                <div className="executiveBox-rightTop">
                                    <h3 className="executiveBox-subhead">Michael Benchamin</h3>
                                    <p className="executiveBox-para">Position</p>
                                </div>
                                <div className="executiveBox-rightBottom">
                                    <img src="URL_TO_IMAGE" alt="DemoCupeMember" className="img-fluid" width="100" height="100" />
                                    <a className="executiveBox-mailText" href="mailto:benchaminmichael@gmail.com">benchaminmichael@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="executiveBox-inline">
                        <div className="executiveBox">
                            <div className="executiveBox-right">
                                <div className="executiveBox-rightTop">
                                    <h3 className="executiveBox-subhead">Willliam shanter</h3>
                                    <p className="executiveBox-para">Position</p>
                                </div>
                                <div className="executiveBox-rightBottom">
                                    <img src="URL_TO_IMAGE" alt="william" className="img-fluid" width="100" height="100" />
                                    <a className="executiveBox-mailText" href="mailto:william@gmail.com">williamshanter@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    );
}

export default Executive;
