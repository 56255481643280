import React, { Component, useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import servicesImg from "../images/services.png"
import noteImg from "../images/note.png"
import messegeImg from "../images/messege.png"
import membersImg from "../images/members.png"
import socialMedia from "../images/Social-media-icon.png"
import homeImg from "../images/home.png"
import helpImg from "../images/help.png"
import emailImg from "../images/email.png"
import dashboardImg from "../images/dashboard.png"
import contractImg from "../images/contract.png"
import Ads01 from '../images/ads01.png'
import Ads02 from '../images/ads02.png'
export default function Sidebar() {
  
 
  return (

    <div className="d-flex flex-column flex-shrink-0 bg-light sidebar">

      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          
        <li>
          <Link className="nav-link border-bottom" id="services-menu" to="/site-admin/profile">
            <img src={servicesImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Profile </span>
          </Link>
        </li>
         
        {/* <li>
          <Link className="nav-link border-bottom" id="promotion-menu" to="/site-admin/promotion">
            <img src={membersImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Promotion </span>
          </Link>
        </li> */}
        <li>
          <Link className="nav-link border-bottom" id="dashboard-menu" to="/site-admin/dashboard">
            <img src={dashboardImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Dashboard </span>
          </Link>
        </li>
      <li>
          <Link className="nav-link border-bottom" id="contract-menu" to="/site-admin/contract">
            <img src={contractImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Contract</span>
          </Link>
        </li>
        <li>
          <Link className="nav-link border-bottom" id="union-menu" to="/site-admin/union">
            <img src={homeImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Union</span>
          </Link>
        </li>
       
        <li>
          <Link className="nav-link border-bottom" id="members-menu" to="/site-admin/member">
            <img src={membersImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Members </span>
          </Link>
        </li>
        <li>
          <Link className="nav-link border-bottom" id="services-menu1" to="/site-admin/services">
            <img src={servicesImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Services </span>
          </Link>
        </li>
        {/* <li>
          <Link className="nav-link border-bottom" id="services-menu" to="/services-enable">
            <img src={servicesImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Services Permission </span>
          </Link>
        </li>
        <li>
          <Link className="nav-link border-bottom" id="messages-menu" to="#">
            <img src={messegeImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Messages </span>
          </Link>
        </li> */}
        {/* <li>
          <Link className="nav-link border-bottom" id="grievances-menu" to="/site-admin/grievance">
            <img src={noteImg} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Grievances </span>
          </Link>
        </li> */}
        <li>
          <Link className="nav-link border-bottom" id="ads-menu" to="/site-admin/ads">
            <img src={Ads02} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Ads </span>
          </Link>
        </li>
        <li>
          <Link className="nav-link border-bottom" id="social-media-menu" to="/site-admin/social-media">
            <img src={socialMedia} className="sidebar-icon" alt="" width={30} height={30}></img>
            <span className="menu-span">Social Media </span>
          </Link>
        </li>
        
      </ul>
    </div>
  );
}

