import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import { ToastContainer, toast } from 'react-toastify';
import homeBanner from '../images/equality_bg01.webp'

function EqualityStatementEdit() {
    return (
        <div className="container-fluid">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"E"}
                theme="colored" />
           <div class="edit_admin_div">
   <div class="edit_admin_maindiv">
      <div class="edit_admin_divtag">
         <div class="edit_image_div w-100">
            <img src={homeBanner} alt="Equality Image" width="300" height="200" class="img-fluid mb-3"/>

            </div>
         <h3 class="edit_adming_head">Equality Statement</h3>
         <h4 class="edit_admin_subhead">A FORWARD-LOOKING APPROACH TO BUSINESS..</h4>
         <div>
            <p>Union solidarity is based on the principle that union members are equal and deserve mutual respect at all levels. Any behaviour that creates conflict prevents us from working together to stengthen our union</p>
           
            <p>As unionists, mutual respect, cooperation and understanding are our goals. We should neither condone nor tolerate behaviour that undermines the dignity or self-esteem of any individual or creates an intimidating, hostile or offensive environment.</p>
            
            <p>Discriminatory speech or conduct which is racist, sexist, transphobic or homophobic hurts and thereby divides us. So too, does discrimination on the basis of ability, age, class, religion and ethnic origin.</p>
            
            <p>Sometimes discrimination takes the form of harassment. Harassment means using real or perceived power to abuse, devalue or humiliate. Harassment should not be treated as a joke. The uneasiness and resentment that it creates are not feelings that help us grow as a union.</p>
           
            <p>Discrimination and harassment focus on characteristics that make us different; and they reduce our capacity to work together on shared concerns such as decent wages, safe working conditions, and justice in the workplace, society and in our union.</p>
           
            <p>CUPE’s policies and practices must reflect our commitment to equality. Members, staff and elected officers must be mindful that all sisters and brothers deserve dignity, equality and respect.</p>
         </div>
      </div>
      <div class="edit_admin_icon">
         <div class="edit_admin_icon_div">
         <Icon.Edit />
         </div>
      </div>
   </div>
</div>
        </div>
    );
}

export default EqualityStatementEdit;
