import React, {Suspense, useState, useEffect } from "react";
import BannerImg from "../images/home-banner.png"
import BannerImgBtm from "../images/1732603_2_mapleleaf1_092523.svg"
import cupeLogo from "../images/sub-logo-cupe.jpg"
import * as Icon from "react-feather";
import Sidebar from "./Layout/sidebar"
let Header = React.lazy(() => import("./Layout/header-admin"))

function Contract() {
     useEffect(() => {
        setTimeout(() => {
        var element = document.getElementById("contract-menu");
        element.classList.add("active");
    }, 1000);  
    }, [])
    return (
        <div className="container-fluid padding-wrapper">
            <Suspense fallback={<div></div>}>
            <Header></Header>
            <div className="container-fluid">
                <div className="banner-top-wrapper">
                    <img src={BannerImg} className="banner-top-img img-fluid"></img>
                    <img src={cupeLogo} className="banner-left-img"></img>
                    <img src={BannerImgBtm} className="banner-top-btmimg"></img>
                </div>
                <div className="row mx-0">
                    <div className="Sidebar-left">
                        <Sidebar />
                    </div>
                    <div className="Sidebar-right d-flex justify-content-center">
                        <div className="card border-0">
                            <div className="card-body pb-5">
                                <h1 className="mainHead fontBold text-capitalize" style={{ color: "#000" }}>Download our contract</h1>
                                <hr style={{ borderBottom: "2px solid #000" }}></hr>
                                <p>Please download the contract below and review it carefully. Once you are satisfied with the terms, please sign and return the contract to us via email.</p>
                                <p><a href="">[Download contract] <Icon.FileText size={18} /></a> </p>
                                <p className="text-muted"><small>Please note: The contract is a legal document, so it is important to read it carefully and understand all of the terms before signing it. If you have any questions, please do not hesitate to contact us.</small></p>
                                <p className="text-muted"><small>Once you have signed and returned the contract to us, we can begin working on your project.</small></p>
                                <p className="text-muted fontBold">Thank you for your cooperation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Suspense>
        </div>
    )
}

export default Contract