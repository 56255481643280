import React, { Component } from "react";
import logofooter from '../images/footer-bottom-logo.png';
import logofooterLine from '../images/pink-line.png';
class Footer extends Component {
    componentDidMount(){
    }
  render() {
    return (
      <footer>
      <div class="footer_top">
          <div class="container">
              <div class="row alignItem-center">
                  <div class="col-md-12">
                     
                      <p className="footer-text">Powered by  <img src={logofooter} class="img-fluid footer_img" alt="logo" width="100" height="100" />
                      {/* Tempest Solutions */}
                      </p>
                      {/* <img src={logofooterLine} className='img-line'></img> */}
                  </div>
              </div>
          </div>
      </div>
  </footer>
    );
  }
}
export default Footer;
