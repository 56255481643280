import React, { useState, useEffect } from "react";
import User from "../images/user2.png";
import * as Icon from "react-feather";


function ProfileInfo() {
    return (
        <div className="position-relative cupe-logo-sub-banner">
            
                
            <img
                src={User}
                alt="subLogoCupe"
                width={200} height={200}
                className="subLogoCupe rounded-circle"
                
            />
            <div className="position-absolute profile-upload">
                <form
                    method="post"
                    class="file-btn"
                    enctype="multipart/form-data"
                >
                    <label for="apply" className="label-file">
                        <input
                            type="file"
                            name=""
                            id="apply"
                            accept="image/jpg, image/png, image/jpeg"
                           
                        />
                        <Icon.Camera size={25} />
                    </label>
                </form>
            </div>
            {/* <div className="text-center w-100">
                fileName
            </div> */}
        </div>
    );
    }

export default ProfileInfo;
