import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import Pagination from "react-js-pagination";
// import { useDispatch, useSelector } from 'react-redux';
// import { dashboardMemberListing, dashboardMemberMatchListing, memberDecline, memberAccept, memberApproveUpdate } from '../redux/reducers/memberSlice';
import $ from "jquery";
import Slider from "react-slick";
import 'react-toastify/dist/ReactToastify.css';




function MemberRequest() {
   
    useEffect(() => {
       
        var element = document.getElementById("dashboard-menu");
        element.classList.add("active");
       
    }, []);
    

    return (
        <>
            <div className="col-md-12 px-0">
                <div className="card mb-3">
                    <div className="card-body p-0">
                        <ul className="list-group">
                            <li className="list-group-item border-left-0 border-right-0 border-top-0 py-2">
                                <h5>Member Request</h5>
                                <p className="text-danger mb-0">
                                    <small>1 Pending Requests</small>
                                </p>
                            </li>
                           
                                <React.Fragment>
                                  
                                    <li className="list-group-item rounded-0 border-left-0 border-right-0 cursor-pointer member-list"
                                        data-toggle="modal" data-target="#popupList"
                                      >
                                        <small className="my-0">alenJordan@gmail.com</small>
                                        <Icon.ArrowRight className="float-right" size={12} />
                                    </li>
                                </React.Fragment>
                        </ul>
                    </div>
                </div>
                {/* {dashboardMemberList.length > 10 ?  */}
                <Pagination
                    activePage={10}
                    itemsCountPerPage={10}
                    totalItemsCount={10}
                    pageRangeDisplayed={5}
                    // onChange={handlePageChange}
                // hideNavigation
                // hideDisabled
                />
                {/* :"" } */}

            </div>
         
        </>
    );
}

export default MemberRequest;
