import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import { ToastContainer, toast } from 'react-toastify';

function ContactEdit() {   

    return (
        <div className="container-fluid">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"C"}
                theme="colored" />
            <div className='edit_admin_div'>
            <div class="edit_admin_div">
   <div class="edit_admin_maindiv pl-2 pl-xl-0">
      <div class="edit_admin_divtag">
         <h3 class="edit_adming_head">Contact Us</h3>
         <p class="mb-3 contact_P">
         <Icon.MapPin className='contact_icon' />
         <span className='contact_text'>163 Rue Saint Eustache</span>
         </p>
         <p class="mb-3 contact_P">
         <Icon.Phone className='contact_icon' />
         <span className='contact_text'>+1 (438) 555-7890</span>
         </p>
         <p class="mb-3 contact_P">
         <Icon.Printer className='contact_icon' />
         <span className='contact_text'>Novabogisich@cupedemo.com</span>
         </p>
         <p class="mb-3 contact_P">
         <Icon.Mail className='contact_icon' />
            <span className='contact_text'>Novabogisich</span>
         </p>
         <p class="mb-3 contact_P">
         <Icon.Globe className='contact_icon' />
            <span className='contact_text'>https://Novabogisich.ca/</span>
         </p>
      </div>
      <div class="edit_admin_icon">
         <div class="edit_admin_icon_div">
         <Icon.Edit  />
         </div>
      </div>
   </div>
</div>
            </div>
        </div>
    );
}

export default ContactEdit;
