import React, { useState, useEffect } from "react";
// import select from "./selectAndAdd"
import { ToastContainer, toast } from 'react-toastify';
function UnionInfo(props) {





    return (
        <div className="col-md-12 mb-3">
<ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        />
            <div class="card-body row">
                <div className="col-md-12 text-right"  >



                </div>
                <div className='form-label-group col-md-12' >
                    {/* <p className="mb-0">CB Email</p> */}
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Member Id *"
                        required=""
                        id="memberId"
                        name="memberId"
                    />
                    <label className="form-label-text" for="postalCode">Member Id *</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12'
                >
                    <select
                        name="unit"
                        PlaceHolder="Unit"
                        className="form-control"

                    >
                        <option value="">Unit</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'
                >
                    <select
                        name="unionPosition"
                        PlaceHolder="UnionPosition*"
                        id="unionPositionID"
                        className="form-control"

                    >
                         <option value="">UnionPosition</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="location"
                        className="form-control"
                    >
                          <option value="">location</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="membershipType"
                        PlaceHolder="Membership Type*"
                        className="form-control"
                    >
                         <option value="">Membership Type*</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="committe"
                        PlaceHolder="Committe*"
                        className="form-control"
                    >
                         <option value="">Committe</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="apprenticeLevel"
                        PlaceHolder="Apprentice Level*"
                        className="form-control"
                    >
                          <option value="">Apprentice Level*</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="memberCraft"
                        PlaceHolder="Member Craft"
                        className="form-control"
                    >
                      <option value="">Member Craft</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="memberClass"
                        PlaceHolder="Member Class"
                        className="form-control"
                    >
                        <option value="">Member Class</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="departmentComplex"
                        PlaceHolder="Department Complex*"
                        className="form-control" 
                        >
                        <option value="">Department Complex*</option>
                    </select>
                        
                </div>
                <div className='form-label-group col-md-12'>
                    <select
                        name="unionStatus"
                        PlaceHolder="Union Status"
                        className="form-control"
                    >
                        <option value="">Union Status</option>
                    </select>
                </div>
                <div className='form-label-group col-md-12'>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="pdfFunds"
                        name="pdfFunds"
                        required=""
                        // name=""
                        id="pdfFunds"
                    />
                    <label className="form-label-text" for="pdfFunds">PdfFunds</label>
                </div>
                <div className='form-label-group col-md-12'>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="PDSent"
                        name="pDSent"
                        required=""
                        // name=""
                        id="pDSent"
                    />
                    <label className="form-label-text" for="pDSent">PDSent</label>
                </div>
                <div className='form-label-group col-md-12' >
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Workshop"
                        name="workshop"
                        required=""
                        // name=""
                        id="workshop"
                    />
                    <label className="form-label-text" for="workshop">Work Shop</label>
                </div>
                <div className='form-label-group col-md-12'>
                    <input
                        type="date"
                        className="form-control"
                        placeholder="Date and Time"
                        name="startDate"
                        required=""
                        // name=""
                        id="startdate"
                    />
                    <label className="form-label-text" for="startdate">Start Date</label>
                </div>
                <div className='form-label-group col-md-12' >

                    <input
                        type="text"
                        className="form-control"
                        placeholder="seniority number"
                        required=""
                        name="seniorityNumber"
                        id="seniorityNumber"
                    />
                    <label className="form-label-text" for="seniority_number">Seniority Number</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12' >

                    <input
                        type="text"
                        className="form-control"
                        placeholder="responsibility"
                        required=""
                        name="responsibility"
                        id="responsibility"
                    />
                    <label className="form-label-text" for="responsibility">Responsibility</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12' >
                    <input
                        type="text"
                        className="form-control"
                        placeholder="ETFO Number"
                        required=""
                        name="eTFONumber"
                        id="eTFONumber"
                    />
                    <label className="form-label-text" for="eTFONumber">ETFO Number</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12'>

                    <input
                        type="text"
                        className="form-control"
                        placeholder="Seniority"
                        required=""
                        id="seniority"
                        name="seniority"
                    />
                    <label className="form-label-text" for="Seniority">Seniority</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12'>

                    <input
                        type="text"
                        className="form-control"
                        placeholder="Time Type Description"
                        required=""
                        id="timeTypeDescription"
                        name="timeTypeDescription"

                    />
                    <label className="form-label-text" for="timeTypeDescription">Time Type Description<span className="red ml-1"></span></label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className='form-label-group col-md-12' >

                    <input
                        type="text"
                        className="form-control"
                        placeholder="SeniorityAsOf"
                        name="SeniorityAsOf"

                        required=""
                        id="SeniorityAsOf"

                    />
                    <label className="form-label-text" for="SeniorityAsOf">SeniorityAsOf</label>
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-12 text-right"  >


                    <button className="btn btn-dark col-md-3 col-lg-2 m-2" onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                        Edit
                    </button>

                    <span>
                        {/* <button className="btn btn-dark col-md-2 m-2" onClick={(e) => submitClick(e.preventDefault(), editOrSaveBotton)} >
                                    {editOrSaveBotton}
                                </button> */}
                        <button className="btn btn-outline-dark col-md-3 col-lg-2 m-2"  onClick={(e) =>  toast.error("Sorry! We can't process this action. It's a demo site.")}>
                            CANCEL
                        </button>
                    </span>

                </div>
            </div>

            {/* <div className="col-md-12 text-center">
                <div className="mb-2">No Information in this Tab</div>
                <button className="btn btn-info">ADD INFO</button >
            </div> */}
        </div>
    );
}

export default UnionInfo;
